import React, {FC, useEffect} from 'react';
import s from './ArtifactsImageSwiperItem.module.scss'
import cl from 'classnames'
import absence from '../../../../../assets/images/photo_absence.svg'
import { NavLink } from 'react-router-dom';
import {useSwiper} from "swiper/react";

const ArtifactsImageSwiperItem:FC<MyProps> = ({img, id, isCurrent, step, isVisited = false}) => {

    const swiper = useSwiper()
    console.log(isVisited)
    useEffect(() => {
        console.log(step)
        swiper.slideTo(step)
    }, [step])

    return (
        <>
            <NavLink to={`/artifacts/${id}`} className={cl(s.container, isVisited && s.visited)}>
                <div className={s.inner}>
                    {
                        img ?
                        <img className={s.itemImg} src={img} alt="artifact"/>
                        :
                        <img className={s.absence} src={absence} alt="artifact"/>
                    }
                </div>
            </NavLink>
            <div className={cl(s.dot, isCurrent && s.current)}></div>
        </>
    );
};

export default ArtifactsImageSwiperItem;

type MyProps = {
    img: string | null
    id: number
    step: number
    isCurrent: boolean
    isVisited?: boolean
}
