import React, {useEffect} from 'react';
import s from './Archive.module.scss'
import {useDispatch} from "react-redux";
import {appActions} from "../../redux/app-reducer";
import {NavLink} from "react-router-dom";
import AloneLogoTop from "../../common/AloneLogoTop/AloneLogoTop";
import {ReactComponent as Arrow} from "../../assets/images/arrow_left.svg";
import archive from "../../assets/images/archive.svg";
import {removeExpositionInfo} from "../../utils/utils";
import {expositionActions} from "../../redux/exposition-reducer";
import {ExpositionType} from "../../types/Types";

const Archive = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        removeExpositionInfo()
        dispatch(appActions.setArchived(false))
        dispatch(expositionActions.setExpositionParams(0, '', ''))
        dispatch(expositionActions.expositionDataReceived({} as ExpositionType, ''))
    }, [])

    return (
        <div className={s.container}>
            <div className={s.top}>
                <AloneLogoTop />
                <div className='pageTitle'>
                    Ошибка
                </div>
                <div className={s.errorImg}>
                    <img src={archive} alt='error' />
                </div>
                <div className={s.title}>
                    Извините, объект находится <span>в архиве</span>
                </div>
            </div>
            <div className={s.footer}>
                <NavLink to='/' className={s.buttonBack}>
                    <Arrow fill='#fff' />
                    <span>На главную</span>
                </NavLink>
            </div>
        </div>
    );
};

export default Archive;