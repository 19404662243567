import React, {FC} from 'react';
import styled from "styled-components";
import {Bottom, ContentMedium, CrossSmall, LabSmall, LogoSmall, RuleMedium,} from "../Common/CommonStyled";

import logo_color from '../../../assets/images/print_logo_color.png'
import lab from '../../../assets/images/lab.svg'
import cross from '../../../assets/images/ticket_white_cross.svg'
import {ArtifactItemType} from "../../../types/Types";
import {baseURL} from "../../../api/api";

const QRCode = require('qrcode.react');

const TicketLayout = styled.div`
    background-color: #F2F3F7;
    width: 280px;
    height: 397px;
    position: relative;
    margin: 0 auto;
    right: 165px;
    &:nth-child(4n-3) {
      border-right: 2px solid black;
    }
    &:nth-child(4n-2) {
      border-right: 2px solid black;
    }
    &:nth-child(4n-1) {
      border-right: 2px solid black;
    }
    &:nth-child(8n-7) {
      border-bottom: 1px solid black;
    }
    &:nth-child(8n-6) {
      border-bottom: 1px solid black;
    }
    &:nth-child(8n-5) {
      border-bottom: 1px solid black;
    }
    &:nth-child(8n-4) {
      border-bottom: 1px solid black;
    }
`
const Id = styled.div({
    color:'#7764F5',
    fontSize: 16,
    fontWeight: 700,
    textAlign: "center",
    textTransform: "uppercase",
    marginBottom: 15,
    letterSpacing:'0.1em',
})
const QrContainer = styled.div`
      padding: 15px;
      width: 205px;
      height: 205px;
      background-color: white;
      margin-bottom: 10px;
    `
const Name = styled.div({
    textAlign: 'center',
    color: '#7764F5',
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
})

const SmallQr:FC<{item: ArtifactItemType, section: string}> = React.memo(({item, section}) => {
    return (
        <TicketLayout>
            <ContentMedium>
                <Name>
                    {item.name}
                </Name>
                <QrContainer>
                    <QRCode
                        value={baseURL + (section === 'rooms' ? `rooms/${item.id}` : `artifacts/${item.id}`)}
                        size={175}
                    />
                </QrContainer>
                <RuleMedium>
                    Отсканируйте QR-код
                </RuleMedium>
                {
                    section === 'rooms' &&
                    <Id>ID: 2_{item.id}</Id>
                }
                {
                    section === 'artifacts' &&
                    <Id>ID: 1_{item.id}</Id>
                }
                <Bottom>
                    <LogoSmall src={logo_color} alt='logo' />
                    <CrossSmall src={cross} alt='cross' />
                    <LabSmall src={lab} alt='lab' />
                </Bottom>
            </ContentMedium>
        </TicketLayout>

    );
})

export default SmallQr;
