import React, {FC, useEffect, useState} from 'react';
import s from './Rooms.module.scss'
import {useDispatch} from "react-redux";
import {actions, getRoomsList} from "../../../../redux/print-reducer";
import {PrintRoomType} from "../../../../types/Types";
import TitleItem from "../TitleItem/TitleItem";
import {ReactComponent as Room} from "../../../../assets/images/print_room.svg";
import Search from "../../../../common/Search/Search";
import RoomItems from "./RoomItems/RoomItems";
import PrintTypeBtn from "../../../../common/PrintTypeBtn/PrintTypeBtn";

const Rooms:FC<MyProps> = ({expositionName, togglePrintTypes, printType}) => {
    const dispatch = useDispatch()
    const [letters, setLetters] = useState('')
    useEffect(() => {
        dispatch(getRoomsList(letters))
        return () => {
            dispatch(actions.roomsListReceived(expositionName, [] as Array<PrintRoomType>))
            dispatch(actions.removeSelectedItems())
        }
    }, [dispatch])

    const handleKeyUp = (e:any) => {
        if(e.keyCode === 13) {
            dispatch(getRoomsList(letters))
        }
    }

    const handleSubmit = () => {
        dispatch(getRoomsList(letters))
    }

    return (
        <>
            <div className={s.top}>
                <div className={s.name}>
                    <TitleItem Ico={Room} title='Комнаты' />
                    <div className={s.exposition}>
                        Выставка
                        «{expositionName}»
                    </div>
                </div>
                <Search handleSubmit={handleSubmit} handleKeyUp={handleKeyUp} setLetters={setLetters} letters={letters} />
            </div>

            <PrintTypeBtn printType={printType} constPrintType={'qr'} togglePrintTypes={togglePrintTypes} text={'Печатать QR'}/>
            {/*<PrintTypeBtn printType={printType} constPrintType={'qr+ico'} togglePrintTypes={togglePrintTypes} text={'Печатать QR и ICO'}/>*/}
            {/*<PrintTypeBtn printType={printType} constPrintType={'ico'} togglePrintTypes={togglePrintTypes} text={'Печатать ICO'}/>*/}

            <RoomItems printType={printType} />
        </>
    );
};

export default Rooms;

type MyProps = {
    printType: string,
    expositionName: string,
    togglePrintTypes: (printType: string) => void
}