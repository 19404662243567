import React, {FC} from 'react';
import s from './VisitedExpositionsItem.module.scss'
import {ExpositionParametersType} from "../../../../../types/Types";

const VisitedExpositionsItem:FC<ExpositionParametersType & MyPropsType> = ({token, exposition_id, handleSelect,
                                                                 exposition_type, exposition_name}) => {
    return (
        <div onClick={() => handleSelect(token, exposition_id, exposition_type, exposition_name)} className={s.container}>
            <div className={s.name}>
                {exposition_name}
            </div>
            <div className={s.type}>
                {exposition_type}
            </div>
        </div>
    );
};

export default VisitedExpositionsItem;

type MyPropsType = {
    handleSelect: (token: string, exposition_id: number,
                   exposition_type: 'Свободный вход' | 'По билетам', exposition_name: string) => void
}
