import React, {FC} from 'react';
import {ArtifactAudioType} from "../../../../types/Types";
import s from "../Artifact.module.scss";
import AudioItem from "../../../../common/AudioItem/AudioItem";

const ArtifactAudio:FC<MyProps> = ({audios, toggleAudio, playingIndex}) => {
    return (
        <>
            {
                audios?.length > 0 &&
                <div className={s.audioContainer}>
                    {
                        audios.map((audio: ArtifactAudioType, index) => {
                            return <AudioItem index={index} playingIndex={playingIndex} key={audio.audio} toggleAudio={toggleAudio} audio={audio} />
                        })
                    }
                </div>
            }
        </>
    );
};

export default ArtifactAudio;

type MyProps = {
    audios: Array<ArtifactAudioType>
    playingIndex: number,
    toggleAudio:(index: number) => void,
}