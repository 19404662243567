import React, {useEffect, useState} from 'react';
import s from './ConnectedRooms.module.scss'
import {NavLink, Redirect, useRouteMatch} from "react-router-dom";
import AloneLogoTop from "../../../common/AloneLogoTop/AloneLogoTop";
import arrow_back from "../../../assets/images/arrow_back.svg";
import map from "../../../assets/images/white_map.svg";
import {useDispatch, useSelector} from "react-redux";
import {actions, getConnectedRoomsList} from "../../../redux/connectedRooms-reducer";
import {AppStateType} from "../../../redux/redux-store";
import {ConnectedRoomType} from "../../../types/Types";
import {getUnvisitedItems, getVisitedItems} from "../../../utils/utils";
import Preloader from "../../../common/Preloader/Preloader";
import ConnectedRoomList from "./ConnectedRoomList/ConnectedRoomList";

const ConnectedRooms = () => {
    const dispatch = useDispatch()
    const match = useRouteMatch<MatchType>()
    const [isError, setIsError] = useState(false)
    const [visitedRooms, setVisitedRooms] = useState<Array<ConnectedRoomType>>([])
    const [unvisitedRooms, setUnvisitedRooms] = useState<Array<ConnectedRoomType>>([])
    const connectedRooms = useSelector((state:AppStateType) => state.connected.connected_rooms)
    const isFetch = useSelector((state: AppStateType) => state.app.isFetch)
    const token = useSelector((state: AppStateType) => state.app.token)
    const currentRoom = useSelector((state: AppStateType) => state.connected.currentRoom)

    useEffect(()=> {
        if(!isNaN(Number(match.params.id))) {
            dispatch(getConnectedRoomsList(token, Number(match.params.id)))
        }
        else {
            setIsError(true)
        }

        return () => {
            dispatch(actions.connectedRoomsListErased())
        }
    }, [dispatch, match.params.id, token])

    useEffect(() => {
        //Если пришла информация по комнате
        const visited_rooms = localStorage.getItem('visited_rooms')
        if(connectedRooms.length > 0) {
            //В случае самого первого визита на страницу комнаты записываем текущую комнату в хранилище устройства, а остальные в
            //массив непосещенных
            if(visited_rooms === null) {
                if(Number(match.params.id) > 0 && !isNaN(Number(match.params.id))) {
                    localStorage.setItem('visited_rooms', JSON.stringify([Number(match.params.id)]))
                    setUnvisitedRooms(connectedRooms)
                }
            }
            else {
                //массив айдишников посещенных комнат
                const rooms = JSON.parse(visited_rooms)

                //Есть ли текущая комната в массиве, добавляем, если нет
                const currentRoom = rooms.find((roomId: number) => roomId === Number(match.params.id))
                if(!currentRoom) {
                    localStorage.setItem('visited_rooms', JSON.stringify([...rooms, Number(match.params.id)]))
                }

                //Массив айдишников посещенных комнат без текущей комнаты
                const filteredRooms = rooms.filter((id: number) => id !== Number(match.params.id))
                //Список посещенных комнат
                const visitedClear = getVisitedItems(connectedRooms, filteredRooms)
                setVisitedRooms(visitedClear)
                //Список непосещенных комнат
                const unvisited = getUnvisitedItems(connectedRooms, filteredRooms)
                setUnvisitedRooms(unvisited)
            }
        }
        else {
            if(visited_rooms === null) {
                if (Number(match.params.id) > 0 && !isNaN(Number(match.params.id))) {
                    localStorage.setItem('visited_rooms', JSON.stringify([Number(match.params.id)]))
                }
            }
            else {
                const rooms = JSON.parse(visited_rooms)
                const currentRoom = rooms.find((roomId: number) => roomId === Number(match.params.id))
                if(!currentRoom) {
                    localStorage.setItem('visited_rooms', JSON.stringify([...rooms, Number(match.params.id)]))
                }
            }
        }
    }, [connectedRooms, match.params.id])

    if(isFetch) {
        return <Preloader />
    }
    if(isError) {
        return <Redirect to='not_found' />
    }

    return (
        <div className={s.container}>
            <div className={s.top}>
                <AloneLogoTop />
                <div className='pageTitle'>
                    <NavLink to={`/rooms/${match.params.id}`}>
                        <img src={arrow_back} alt='arrow_back' />
                    </NavLink>
                    Соседние комнаты
                </div>
                <div className={s.current}>
                    Текущая комната
                    <div className={s.roomName}>
                        {currentRoom.name}
                    </div>
                </div>
                <ConnectedRoomList rooms={unvisitedRooms} title='Непосещенные' isVisited={false} />
                <ConnectedRoomList rooms={visitedRooms} title='Посещенные' isVisited={true} />
                {
                    (visitedRooms.length === 0 && unvisitedRooms.length === 0) &&
                    <div className={s.noRooms}>
                        Комнаты отсутствуют
                    </div>
                }
            </div>

            <div className={s.map}>
                <NavLink to={`/map/${match.params.id}`} className={s.link}>
                    <img src={map} alt='map' />
                    <span>
                        Показать на карте
                    </span>
                </NavLink>
            </div>
        </div>
    );
};

export default ConnectedRooms;

type MatchType = {
    id: string,
}