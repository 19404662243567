import {BaseThunkType, InferActionsTypes} from "./redux-store";
import {MapType} from "../types/Types";
import {appActions, AppActionsType} from "./app-reducer";
import {mapAPI} from "../api/map-api";
import {StatusCodesEnum} from "../api/api";
import {expositionActions, ExpositionActionsType} from "./exposition-reducer";
import {
    addExpositionItemInArray,
    removeExpositionInfo,
    removeExpositionItemFromArray,
    setExpositionInfo
} from "../utils/utils";

export type InitialStateType = typeof initialState
let initialState = {
    roomsList: [] as Array<MapType>,
    expositionMapImage: null as string | null
}

//reducer возвращает стейт такого же значения, что и принимает, чтобы мы в кейсах не дописывали новые поля объекту
const mapReducer = (state = initialState, action: ActionsType):InitialStateType  => {
    switch (action.type) {
        case 'AW/MAP/MAP_LIST_RECEIVED':
            return {
                ...state,
                //Дополнительно засовываем в массив два свойства
                roomsList: action.payload.roomsList.map((room:MapType) => ({
                    ...room,
                    isVisited: false, //Посещена ли комната
                    isCurrentRoom: false, //Находимся ли мы в текущей комнате
                }))
            }
        case 'AW/MAP/EXPOSITION_MAP_IMAGE_RECEIVED':
            return {
                ...state,
                expositionMapImage: action.payload.expositionMapImage,
            }
        default:
            return state;
    }
}

type ActionsType = InferActionsTypes<typeof MapActions>

export const MapActions = {
    mapListReceived: (roomsList: Array<MapType>) =>
        ({type: 'AW/MAP/MAP_LIST_RECEIVED', payload: {roomsList}} as const),
    mapImageReceived: (expositionMapImage: string | null) =>
        ({type: 'AW/MAP/EXPOSITION_MAP_IMAGE_RECEIVED', payload: {expositionMapImage}} as const),

}

type ThunkType = BaseThunkType<ActionsType | AppActionsType | ExpositionActionsType>

export const getMapList = (token: string, exposition_id: number): ThunkType => {
    return async (dispatch) => {
        dispatch(appActions.toggleIsFetching(true))
        try {
            let data = await mapAPI.getMapList(token, exposition_id)
            console.log('getMapList', data)
            if(!data.status) {
                dispatch(MapActions.mapListReceived(data.rooms))
                dispatch(MapActions.mapImageReceived(data.exposition_map_image))
                setExpositionInfo(data.exposition_id, data.exposition_type,data.exposition_name)
                addExpositionItemInArray(token, data.exposition_id, data.exposition_type, data.exposition_name)
                dispatch(expositionActions.setExpositionParams(data.exposition_id, data.exposition_name, data.exposition_type))
            }
            else if(data.status === StatusCodesEnum.Forbidden) {
                removeExpositionItemFromArray(token, exposition_id, localStorage.getItem('exposition_type') as 'Свбодный вход' & 'По билетам')
                dispatch(appActions.setForbiddenError(true))
            }
            else if(data.status === StatusCodesEnum.BadRequest) {
                removeExpositionItemFromArray(token, exposition_id, localStorage.getItem('exposition_type') as 'Свбодный вход' & 'По билетам')
                removeExpositionInfo()
                dispatch(appActions.setServerError(true))
            }
            dispatch(appActions.toggleIsFetching(false))
        }
        catch (e: any) {
            console.error('getMapList', e.config)
            dispatch(appActions.toggleIsFetching(false))
            dispatch(appActions.setServerError(true))
        }
    }
}

export default mapReducer;