import React from 'react';
import s from './EnterBottomLinks.module.scss'
import {NavLink} from "react-router-dom";
import main_input_id from "../../assets/images/main_input_id.svg";

const EnterBottomLink = () => {
    return (
        <div className={s.footer}>
            <div className={s.question}>
                Не получается отсканировать?
            </div>
            <NavLink className={s.enterId} to={'/enter'}>
                <img src={main_input_id} alt='input logo' />
                <span>Ввести ID вручную</span>
            </NavLink>
        </div>
    );
};

export default EnterBottomLink;