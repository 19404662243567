import {ErrorResponseType, baseURL, ExpositionItemsType} from "./api";
import axios from "axios";
import {ArtifactType} from "../types/Types";

type GetArtifactDataListResponseType = {
    artifacts: Array<ArtifactType>
}

export const artifactAPI = {
    getArtifactDataList(token: string, id: number) {
        let formdata = new FormData();
        formdata.append('token', token);
        return axios.post<GetArtifactDataListResponseType & ExpositionItemsType & ErrorResponseType>(baseURL + `api/artifacts/${id}`, formdata)
            .then(response => response.data)
    },
}
