import React, {FC} from 'react'
import s from './FilledSmallBtn.module.scss'
import {NavLink} from 'react-router-dom'
import cl from "classnames";

const FilledSmallBtn: FC<{img: string, text:string, link: string}> = ({img, text, link}) => {
    return (
        <NavLink to={link} className={cl(s.scan, s.qr)}>
            <img src={img} alt='ico' />
            <span>{text}</span>
        </NavLink>
    )
}

export default FilledSmallBtn