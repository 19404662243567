import React, {FC, useEffect} from 'react';
import s from './ErrorPage.module.scss'
import {ReactComponent as Arrow} from "../../assets/images/arrow_left.svg";
import { NavLink } from 'react-router-dom';
import AloneLogoTop from "../AloneLogoTop/AloneLogoTop";
import {useDispatch} from "react-redux";
import {appActions} from "../../redux/app-reducer";

const ErrorPage:FC<{img: string, error: number, text: string}> = ({img, error, text}) => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(appActions.setErrorsFalse())
    }, [])
    return (
        <div className={s.container}>
            <div className={s.top}>
                <AloneLogoTop />
                <div className='pageTitle'>
                    Ошибка
                </div>
                <div className={s.errorImg}>
                    <img src={img} alt='error' />
                </div>
                <div className={s.error}>
                    <div className={s.title}>
                        Ошибка {error}
                    </div>
                    <div className={s.rules}>
                        {text}
                    </div>
                </div>
            </div>
            <div className={s.footer}>
                <NavLink to='/' className={s.buttonBack}>
                    <Arrow fill={'white'} />
                    <span>На главную</span>
                </NavLink>
            </div>
        </div>
    );
};

export default ErrorPage;