import React from 'react';
import ErrorPage from "../../common/ErrorPage/ErrorPage";
import img404 from '../../assets/images/404.svg'

const Error404 = () => {
    return (
        <>
            <ErrorPage img={img404} error={404} text={'Упс, что-то пошло не так и мы не нашли то, что вы искали!\n' +
            'Пожалуйста, обратитесь к администратору'} />
        </>
    );
};

export default Error404;