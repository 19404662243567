import React, {FC} from 'react';
import s from './MapImageItem.module.scss'
import {useHistory} from "react-router-dom";

const MapImageItem:FC<MyProps> = ({id, name, x_axis, y_axis, isCurrentRoom, isVisited}) => {
    const history = useHistory()
    return (
        <>
            {
                (x_axis !== null && y_axis !== null) ?
                <div onClick={() => history.push(`/rooms/${id}`)}
                     style={{top: y_axis, left: x_axis}} className={s.container}>
                    <div className={s.name}>{name}</div>
                    <div style={{backgroundColor: isCurrentRoom ? '#7764F5' :(isVisited ? '#00CE84' : '#ED5959')}} className={s.round}></div>
                </div>
                : <></>
            }
        </>
    );
};

export default MapImageItem;

type MyProps = {
    name: string
    id: number
    x_axis: number
    y_axis: number
    isVisited?: boolean
    isCurrentRoom?: boolean
}