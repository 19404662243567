import React, {useState} from 'react';
import s from './Print.module.scss'
import Modal from 'react-png-modal'
import {Redirect, useRouteMatch} from 'react-router-dom'
import NavigationPrompt from "react-router-navigation-prompt";
import Tickets from "./Tickets/Tickets";
import Rooms from "./Rooms/Rooms";
import Artifacts from "./Artifacts/Artifacts";
import {useDispatch, useSelector} from 'react-redux'
import ConfirmButtonModal from '../../../common/Modal/ConfirmButtonModal/ConfirmButtonModal'
import {actions} from '../../../redux/print-reducer'
import SelectSizeModal from '../../../common/Modal/SelectSizeModal/SelectSizeModal'
import PrintHeader from "./PrintHeader/PrintHeader";

const Print = () => {
    const dispatch = useDispatch();
    const match = useRouteMatch();

    const [isLogoutPressed, setIsLogOutPressed] = useState(false)
    const [printSize, setPrintSize] = useState('')
    const [isConfirmRoomsModalOpen, setIsConfirmRoomsModalOpen] = useState(false)
    const [isConfirmArtifactsModalOpen, setIsConfirmArtifactsModalOpen] = useState(false)
    const [isSelectSizeModalOpen, setIsSelectSizeModalOpen] = useState(false)
    const [printType, setPrintType] = useState('qr') //Тип печатаемого билета (qr or qr+ico or ico) для комнат
    const [printTypeTemporary, setPrintTypeTemporary] = useState('') //Временное хранилище переменной

    const expositionName = useSelector((state) => state.print.expositionName)
    const selectedItems = useSelector((state) => state.print.selectedItems)
    const isAuth = useSelector((state) => state.auth.isAuth)

    const handleLogout = () => {
        setIsLogOutPressed(true)
    }

    const togglePrintTypes = (printType) => {
        if(selectedItems?.length > 0) {
            setIsConfirmRoomsModalOpen(true)
            setPrintTypeTemporary(printType)
        }
        else if(selectedItems?.length === 0) {
            setPrintType(printType)
        }
    }

    //Вызывается после закрытия поп-апа с подтверждением
    const confirmChangeIcoArtifacts = () => {
        setIsConfirmArtifactsModalOpen(false)
        dispatch(actions.removeSelectedItems())
    }
    //Вызывается после закрытия поп-апа с подтверждением
    const confirmChangeIcoRooms = () => {
        setPrintType(printTypeTemporary)
        setIsConfirmRoomsModalOpen(false)
        dispatch(actions.removeSelectedItems())
    }

    if(!isAuth || isLogoutPressed) {
        return <Redirect to='/login' />
    }

    return (
        <>
            <NavigationPrompt when={selectedItems.length > 0}>
                {({ onConfirm, onCancel }) => (
                    <Modal
                        center
                        closeModal={onCancel}
                        open={true}
                        className={s.modal}
                    >
                        <ConfirmButtonModal confirmChangeIco={onConfirm} />
                    </Modal>
                )}
            </NavigationPrompt>

            <div className={s.print}>

                <PrintHeader selectedItems={selectedItems} handleLogout={handleLogout} setIsSelectSizeModalOpen={setIsSelectSizeModalOpen}
                             printSize={printSize} printType={printType} />
                <div className={s.content}>
                    {
                        match.params.section === 'tickets' &&
                            <Tickets expositionName={expositionName} />
                    }
                    {
                        match.params.section === 'rooms' &&
                            <Rooms printType={printType} togglePrintTypes={togglePrintTypes} expositionName={expositionName} />
                    }
                    {
                        match.params.section === 'artifacts' &&
                            <Artifacts printType={printType} togglePrintTypes={togglePrintTypes} expositionName={expositionName} />
                    }

                </div>

            </div>
            <Modal
                closeModal={() => setIsConfirmRoomsModalOpen(false)}
                open={isConfirmRoomsModalOpen}
                className={s.modal}
                center
            >
                <ConfirmButtonModal confirmChangeIco={confirmChangeIcoRooms} />
            </Modal>
            <Modal
                closeModal={() => setIsConfirmArtifactsModalOpen(false)}
                open={isConfirmArtifactsModalOpen}
                className={s.modal}
                center
            >
                <ConfirmButtonModal confirmChangeIco={confirmChangeIcoArtifacts} />
            </Modal>
            <Modal
                closeModal={() => setIsSelectSizeModalOpen(false)}
                open={isSelectSizeModalOpen}
                className={s.modal}
                center
            >
                <SelectSizeModal selectedItems={selectedItems} printType={printType} printSize={printSize}
                                 section={match.params.section} setPrintSize={setPrintSize}
                                 isIcoExists={(printType === 'qr+ico' || printType === 'ico')}
                />
            </Modal>
        </>
    );
};

export default Print;
