import React, {FC, FunctionComponent, SVGProps} from 'react';
import s from './TitleItem.module.scss'

const TitleItem:FC<MyProps> = ({title, Ico}) => {
    return (
        <div className={s.container}>
            <div className={s.imgContainer}>
                <Ico fill='#7764F5' />
            </div>
            <h3 className={s.title}>{title}</h3>
            <div className={s.dot}></div>
        </div>
    );
};

export default TitleItem;

type MyProps = {
    Ico: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined; }>
    title: string
}