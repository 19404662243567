import React, {FC, useEffect, useState} from 'react'
import {Redirect, Route, Switch, useHistory} from 'react-router-dom'
import Exposition from './Exposition/Exposition'
import QrScan from "./QrScan/QrScan";
import Enter from "./Enter/Enter";
import Room from "./Room/Room";
import ConnectedRooms from "./ConnectedRooms/ConnectedRooms";
import Map from "./Map/Map";
import {useDispatch} from "react-redux";
import {appActions} from "../../redux/app-reducer";
import PreloaderLogo from "../../common/PreloaderLogo/PreloaderLogo";
import Information from "./Information/Information";
import VisitedExpositions from "./VisitedExpositions/VisitedExpositions";
import Preloader from "../../common/Preloader/Preloader";
import {compose} from "redux";
import {withErrorRedirect} from "../../hoc/withErrorRedirect";
import Artifact from "./Artifact/Artifact";

const Container: FC = () => {
    const history = useHistory()
    const url = new URLSearchParams(history.location.search)
    const [isTokenSet, setIsTokenSet] = useState(false)
    //token с query параметров
    const [urlToken] = useState(url.get('token'))
    //Локальный токен из памяти приложения
    const [localToken] = useState(localStorage.getItem('token'))
    const dispatch = useDispatch()

    //Установка токена в глобальное хранилище
    useEffect(() => {
        //urlToken и localToken не могут быть одновременно равны null, иначе никак не попасть на выставку
        //Если токен в приложении совпал с токеном из отсканированного билета
        if(urlToken === localToken && urlToken !== null) {
            dispatch(appActions.tokenReceived(urlToken)) //сетаю токен
            setIsTokenSet(true)
        }
        //Если токены не совпали, то приоритет отдается отсканированному
        else if(urlToken !== localToken) {
            //Если есть отсканированный токен
            if(urlToken !== null) {
                localStorage.setItem('token', urlToken)
                dispatch(appActions.tokenReceived(urlToken))
                setIsTokenSet(true)
            }
            //Иначе возвращаем старый
            else if(localToken !== null) {
                dispatch(appActions.tokenReceived(localToken))
                setIsTokenSet(true)
            }
        }
        else if (urlToken === localToken && urlToken === null) {
                setIsTokenSet(true)
        }
    }, [dispatch, urlToken, localToken])

    if(!isTokenSet) return <Preloader />

    return (
        <>
            <PreloaderLogo />
            <div className={'outer'}>
                <div className={'container'}>
                    <Switch>
                        <Route exact path='/' render={() => <Exposition />} />
                        <Route exact path='/qr_scan' render={() => <QrScan />} />
                        <Route exact path='/history' render={() => <VisitedExpositions />} />
                        <Route exact path='/information' render={() => <Information />} />
                        {/*<Route exact path='/ico_scan' render={() => <IcoPhoto />} />*/}
                        <Route exact path='/enter' render={() => <Enter />} />
                        <Route exact path='/rooms/:id' render={() => <Room />} />
                        <Route exact path='/connected_rooms/:id' render={() => <ConnectedRooms />} />
                        <Route exact path='/artifacts/:id' render={() => <Artifact />} />
                        <Route exact path='/map/:id?' render={() => <Map />} />
                        <Route exact path='*' render={() => <Redirect to='/not_found'/>} />
                    </Switch>
                </div>
            </div>
        </>
    )
}

export default compose(withErrorRedirect)(Container)