import React, {FC} from "react";
import s from './Search.module.scss'
import loup from "../../assets/images/loup.svg";

const Search:FC<MyProps> = ({handleKeyUp, setLetters, letters, handleSubmit}) => {
    return (
        <div className={s.search}>
            <label htmlFor='search'>
                <button onClick={handleSubmit}>
                    <img src={loup} alt='loup' />
                </button>
            </label>
            <input onKeyUp={(e) => handleKeyUp(e)} id='search' type='text' value={letters}
                   onChange={(e) => setLetters(e.target.value)} placeholder={'Поиск'} />
        </div>
    );
};

export default Search;

type MyProps = {
    handleKeyUp:(e:any) => void,
    handleSubmit:() => void,
    setLetters:(letters:string) => void,
    letters:string
}