import React, {FC} from 'react';
import active_ticket from '../../../assets/images/print_active_ticket.svg'
import logo_color from '../../../assets/images/print_logo_color.png'
import lab from '../../../assets/images/lab.svg'
import cross from '../../../assets/images/ticket_white_cross.svg'
import styled from "styled-components";
import {AliveTicketType} from "../../../types/Types";
import {useSelector} from "react-redux";
import {AppStateType} from "../../../redux/redux-store";
import {baseURL} from "../../../api/api";

const QRCode = require('qrcode.react');

const TicketLayout = styled.div({
    backgroundColor: '#F2F3F7',
    width: 792,
    height: 1122,
})

const Title = styled.div({
    display: 'block',
    color: '#7764F5',
    fontSize: 48,
    fontWeight: 800,
    fontStyle: 'italic',
    margin: '0 auto 25px',
})

const Content = styled.div`
      padding: 50px;
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 0 auto 70px;
    `
const ImageContainer = styled.div`
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(8, 29, 63, 0.05);
      border-radius: 50%;
      margin-bottom: 60px;
    `
const Illustration = styled.img`
      display: block;
      width: 42px;
      height: 42px;
    `
const Ticket = styled.div`
      display: block;
      color: #081D3F;
      font-weight: 400;
      font-size: 48px;
      text-align: center;
    `

const Line = styled.div`
      width: 125px;
      height: 2px;
      background: rgba(0, 0, 0, 0.05);
      margin: 0 auto 85px;
    `
const TicketImageContainer = styled.div`
      width: 320px;
      height: 320px;
      padding: 20px;
      border: 2px solid rgba(0, 0, 0, 0.15);
      filter: drop-shadow(10px 10px 70px rgba(0, 0, 0, 0.1));
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      background-color: white;
    `
const Rule = styled.div`
      margin-bottom: 150px;
      font-size: 18px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-weight: 700;
      color: rgba(8, 29, 63, 0.6);
    `
const Bottom = styled.div`
      display: flex;
      align-items: center;
    `
const Cross = styled.img`
      width: 7px;
      display: block;
    `
const Logo = styled.div`
      margin-right: 12px;
    `
const Lab = styled.div`
      margin-left: 18px;
    `
const Img = styled.img``

const TicketPrint:FC<{item: AliveTicketType, section: string}> = React.memo(({item}) => {
    const expositionName = useSelector((state:AppStateType) => state.print.expositionName)
    return (
        <TicketLayout>
            <Content>
                <ImageContainer>
                    <Illustration src={active_ticket} alt="ticket" />
                </ImageContainer>
                <Ticket>
                    Билет {item.id}
                </Ticket>
                <Title>
                    {expositionName}
                </Title>
                <Line></Line>
                <TicketImageContainer>
                    <QRCode
                        value={baseURL + `?token=${item.token}`}
                        size={280}
                    />
                </TicketImageContainer>
                <Rule>
                    Отскнируйте QR-код
                </Rule>

                <Bottom>
                    <Logo>
                        <Img src={logo_color} alt='logo' />
                    </Logo>
                    <Cross src={cross} alt='cross' />
                    <Lab>
                        <Img src={lab} alt='lab' />
                    </Lab>
                </Bottom>
            </Content>
        </TicketLayout>
    );
})

export default TicketPrint;