import {BaseThunkType, InferActionsTypes} from "./redux-store";
import {printAPI} from "../api/print-api";
import {AliveTicketType, ArtifactItemType, PrintRoomType} from "../types/Types";
import {appActions, AppActionsType} from "./app-reducer";
import {authActions, AuthActionsType} from "./auth-reducer";

export type InitialStateType = typeof initialState
let initialState = {
    expositionName: '' as string, //Название выставки
    aliveTickets: [] as Array<AliveTicketType>, //Массив ивых билетов
    rooms: [] as Array<PrintRoomType>, //Массив всех комнат
    artifacts: [] as Array<ArtifactItemType>, //Массив экспонатов, которые отображаются на стр.
    pageSize: 9 as number, //Сколько экспонатов выводится на странице печати
    count: 0 as number, //Общее количество экспонатов
    currentPage: 1 as number, //Номер текущей страницы с экспонатами
    selectedItems: [] as Array<AliveTicketType | PrintRoomType | ArtifactItemType>, //Массив всех комнат
}

const printReducer = (state = initialState, action:ActionsType):InitialStateType => {
    switch (action.type) {
        case 'AW/PRINT/ALIVE_TICKETS_RECEIVED':
            return {
                ...state,
                expositionName: action.payload.expositionName,
                aliveTickets: action.payload.aliveTickets,
            }
        case 'AW/PRINT/ROOMS_LIST_RECEIVED':
            return {
                ...state,
                expositionName: action.payload.expositionName,
                rooms: action.payload.rooms,
            }
        case 'AW/PRINT/SET_CURRENT_PAGE':
            return {
                ...state,
                currentPage: action.payload.pageNumber,
            }
        case 'AW/PRINT/ARTIFACTS_LIST_RECEIVED':
            return {
                ...state,
                expositionName: action.payload.expositionName,
                artifacts: action.payload.artifacts,
                count: action.payload.count,
            }

        case 'AW/PRINT/SET_SELECTED_ITEM_ID':
            return {
                ...state,
                selectedItems: [...state.selectedItems, action.payload.data],
            }
        case 'AW/PRINT/DELETE_CURRENT_ITEM':
            return {
                ...state,
                selectedItems: state.selectedItems.filter((item) => item.id !== action.payload.data.id),
            }
        case 'AW/PRINT/REMOVE_SELECTED_ITEMS': //Обнуление массива
            return {
                ...state,
                selectedItems: [] as Array<AliveTicketType | PrintRoomType | ArtifactItemType>,
            }

        default:
            return state;
    }
}

export type ActionsType = InferActionsTypes<typeof actions>

export const actions = {
    aliveTicketsReceived: (expositionName:string, aliveTickets:Array<AliveTicketType>) =>
        ({type: 'AW/PRINT/ALIVE_TICKETS_RECEIVED', payload: {expositionName, aliveTickets}} as const),
    setCurrentPage: (pageNumber:number) =>
        ({type: 'AW/PRINT/SET_CURRENT_PAGE', payload: {pageNumber}} as const),
    roomsListReceived: (expositionName:string, rooms:Array<PrintRoomType>) =>
        ({type: 'AW/PRINT/ROOMS_LIST_RECEIVED', payload: {expositionName, rooms}} as const),
    artifactsListReceived: (expositionName:string, artifacts:Array<ArtifactItemType>, count:number) =>
        ({type: 'AW/PRINT/ARTIFACTS_LIST_RECEIVED', payload: {expositionName, artifacts, count}} as const),
    setSelectedItemId: (data:AliveTicketType | PrintRoomType | ArtifactItemType) =>
        ({type: 'AW/PRINT/SET_SELECTED_ITEM_ID', payload: {data}} as const),
    deleteCurrentItem: (data:AliveTicketType | PrintRoomType | ArtifactItemType) =>
        ({type: 'AW/PRINT/DELETE_CURRENT_ITEM', payload: {data}} as const),
    removeSelectedItems: () =>
        ({type: 'AW/PRINT/REMOVE_SELECTED_ITEMS', payload: {}} as const),
}

type ThunkType = BaseThunkType<ActionsType | AppActionsType | AuthActionsType>

export const getAliveTickets = (find_by_letters:string):ThunkType => {
    return async(dispatch) => {
        dispatch(appActions.toggleIsFetching(true))
        try {
            let data = await printAPI.getAliveTickets(find_by_letters)
            console.log('getAliveTickets', data)
            dispatch(actions.aliveTicketsReceived(data.exposition_name, data.alive_tickets))
            dispatch(authActions.setIsAdminInitialize(true))
            dispatch(authActions.login(true))
            dispatch(appActions.toggleIsFetching(false))
        } catch (e: any) {
            console.error('getAliveTickets', e.config)
            dispatch(appActions.toggleIsFetching(false))
            localStorage.removeItem('accessToken')
            if (e.response.status === 401) {
                dispatch(appActions.setForbiddenError(true))
            }
            else {
                dispatch(appActions.setServerError(true))
            }
            dispatch(appActions.setServerError(true))
            dispatch(authActions.logout()) //isAuth = false
            dispatch(authActions.setIsAdminInitialize(true)) //Чтоб не отрисовывался скелет загрузки
        }
    }
}
export const getRoomsList = (find_by_letters:string):ThunkType => {
    return async(dispatch) => {
        dispatch(appActions.toggleIsFetching(true))
        try {
            let data = await printAPI.getRoomsList(find_by_letters)
            console.log('getRoomsList', data)
            dispatch(actions.roomsListReceived(data.exposition_name, data.rooms))
            dispatch(appActions.toggleIsFetching(false))
        } catch (e: any) {
            console.error('getRoomsList', e.config)
            dispatch(appActions.toggleIsFetching(false))
            localStorage.removeItem('accessToken')
            if (e.response.status === 401) {
                dispatch(appActions.setForbiddenError(true))
            }
            else {
                dispatch(appActions.setServerError(true))
            }
            dispatch(appActions.setServerError(true))
            dispatch(authActions.logout())
            dispatch(authActions.setIsAdminInitialize(true))
        }
    }
}
export const getArtifactsList = (find_by_letters:string, pageNumber:number, are_icons_exist:boolean):ThunkType => {
    return async(dispatch) => {
        dispatch(appActions.toggleIsFetching(true))
        try {
            let data = await printAPI.getArtifactsList(find_by_letters, pageNumber, are_icons_exist)
            console.log('getArtifactsList', data)
            dispatch(actions.artifactsListReceived(data.exposition_name, data.artifacts, data.count))
            dispatch(actions.setCurrentPage(pageNumber))
            dispatch(appActions.toggleIsFetching(false))
        } catch (e: any) {
            console.error('getArtifactsList', e.config)
            dispatch(appActions.toggleIsFetching(false))
            localStorage.removeItem('accessToken')
            if (e.response.status === 401) {
                dispatch(appActions.setForbiddenError(true))
            }
            else {
                dispatch(appActions.setServerError(true))
            }
            dispatch(authActions.logout())
            dispatch(authActions.setIsAdminInitialize(true))
        }
    }
}

export default printReducer;