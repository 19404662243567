import React, {FC} from 'react';
import {NavLink} from 'react-router-dom';
import s from './InformationItem.module.scss'
import {ReactComponent as Arrow} from '../../../../../assets/images/arrow_right.svg'
import {useSwiper} from "swiper/react";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const InformationItem:FC<MyProps> = ({number, img, text, alt}) => {
    const swiper = useSwiper();
    return (
        <div className={s.container}>
            <div className={s.item}>
                <div className={s.number}>{number}</div>
                <div className={s.imgContainer}>
                    <LazyLoadImage
                        alt={alt}
                        effect="blur"
                        src={img} />
                    {/*<img src={img} alt={`information` + number} />*/}
                </div>
                <div className={s.text}>{text}</div>
                {
                    number !== 5 ?
                    <div className={s.nav}>
                        <NavLink to={'/'} className={s.skip}>Пропустить</NavLink>
                        <div onClick={() => swiper?.slideNext()} className={s.next}>
                            <span>Далее</span>
                            <Arrow fill='#7764F5' />
                        </div>
                    </div>
                    :
                    <NavLink to={'/'} className={s.last}>
                        <div className={s.next}>
                            <span>Перейти к сервису</span>
                            <Arrow fill='#7764F5' />
                        </div>
                    </NavLink>
                }
            </div>
        </div>
    );
};

export default InformationItem;

type MyProps = {
    number: number
    img: string
    text: string
    alt: string
}