import React, {FC} from 'react';
import cl from "classnames";
import s from './PrintTypeBtn.module.scss'

const PrintTypeBtn:FC<MyProps> = ({togglePrintTypes, printType, constPrintType, text}) => {
    return (
        <button onClick={() => togglePrintTypes(constPrintType)}
                className={printType === constPrintType ? cl(s.activeBtn, s.btn) : cl(s.noActive, s.btn)}>
            {text}
        </button>
    );
};

export default PrintTypeBtn;

type MyProps = {
    printType: string,
    text: string,
    constPrintType: string,
    togglePrintTypes: (printType: string) => void
}