import React from 'react';
import s from './VisitedExpositions.module.scss'
import AloneLogoTop from "../../../common/AloneLogoTop/AloneLogoTop";
import {NavLink, useHistory} from "react-router-dom";
import arrow_back from "../../../assets/images/arrow_back.svg";
import {setExpositionInfo} from "../../../utils/utils";
import {useDispatch} from "react-redux";
import {appActions} from "../../../redux/app-reducer";
import VisitedItems from "./VisitedItems/VisitedItems";

const VisitedExpositions = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const expositions = JSON.parse(localStorage.getItem('expositions') as string)

    const handleSelect = (token: string, id: number, type: 'Свободный вход' | 'По билетам', name: string) => {
        setExpositionInfo(id, type, name)
        if(token !== '') {
            localStorage.setItem('token', token)
            dispatch(appActions.tokenReceived(token))
        }
        history.push('/')
    }

    return (
        <div className={s.container}>
            <AloneLogoTop />
            <div className='pageTitle'>
                <NavLink to='/'>
                    <img src={arrow_back} alt='arrow_back' />
                </NavLink>
                Посещенные выставки
            </div>
            <VisitedItems expositions={expositions} handleSelect={handleSelect} />
        </div>
    );
};

export default VisitedExpositions;