import React, {FC} from 'react';
import {AliveTicketType, ArtifactItemType, PrintRoomType} from "../../../types/Types";

const CommonLogic:FC<MyProps> = ({selectedItems, section, Component}) => {
    return (
        <>
            {
                selectedItems?.length > 0 &&
                    selectedItems
                        .map((item) => {
                            return (
                                <Component section={section} key={item.id} item={item} />
                            )
                        })
            }
        </>
    );
};

export default CommonLogic;

type MyProps = {
    selectedItems: Array<AliveTicketType | PrintRoomType | ArtifactItemType>,
    Component: any,
    section: string
}