import React from 'react';
import s from "./TicketItems.module.scss";
import {AliveTicketType} from "../../../../../types/Types";
import TicketPrintItem from "../TicketPrintItem/TicketPrintItem";
import {useSelector} from "react-redux";
import {AppStateType} from "../../../../../redux/redux-store";
import Preloader from "../../../../../common/Preloader/Preloader";

const TicketItems = () => {
    const aliveTickets = useSelector((state: AppStateType) => state.print.aliveTickets)
    const selectedItems = useSelector((state: AppStateType) => state.print.selectedItems)
    const isFetch = useSelector((state: AppStateType) => state.app.isFetch)

    if(isFetch) {
        return <Preloader />
    }
    return (
        <>
            {
                aliveTickets?.length > 0 ?
                    <div className={s.tickets}>
                        {
                            aliveTickets.map((ticket: AliveTicketType) => {
                                return (
                                    <div key={ticket.id}>
                                        {
                                            selectedItems.some((item) => item.id === ticket.id) ?
                                                <TicketPrintItem active={true} ticket={ticket} />
                                                :
                                                <TicketPrintItem active={false} ticket={ticket} />
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                    :
                    <div className={s.notFound}>
                        Билеты не найдены
                    </div>
            }
        </>
    );
};

export default TicketItems;