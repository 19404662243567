import React, {useEffect} from 'react';
import img403 from '../../assets/images/403.svg'
import ErrorPage from "../../common/ErrorPage/ErrorPage";
import {useDispatch} from "react-redux";
import {appActions} from "../../redux/app-reducer";
import {removeExpositionInfo} from "../../utils/utils";
import {expositionActions} from "../../redux/exposition-reducer";
import {ExpositionType} from "../../types/Types";

const Error403 = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(appActions.tokenReceived(''))
        removeExpositionInfo()
        dispatch(expositionActions.setExpositionParams(0, '', ''))
        dispatch(expositionActions.expositionDataReceived({} as ExpositionType, ''))
    }, [])

    return (
        <>
            <ErrorPage img={img403} error={403} text={'Билет истек или еще не куплен.\n' +
            'Пожалуйста, отсканируйте QR-код из своего билета'} />
        </>
    );
};

export default Error403;