import React, {FC} from 'react';
import s from './Description.module.scss'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const Description:FC<MyProps> = ({description, isMarkdown}) => {
    return (
        <>
            {
                description !== '' &&
                    <div className={s.description}>

                        <div className={s.title}>
                            Описание
                        </div>
                        {
                            isMarkdown ?
                            <ReactMarkdown className='markdown-body' children={description} remarkPlugins={[remarkGfm]} />
                            :
                            <div className={s.text}>
                                {description}
                            </div>
                        }
                    </div>
                }
        </>
    );
};

export default Description;

type MyProps = {
    description: string
    isMarkdown: boolean
}