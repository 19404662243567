import React, {FC} from 'react';
import styled from "styled-components";
import {ArtifactItemType} from "../../../../../types/Types";
import {actions} from "../../../../../redux/print-reducer";
import {useDispatch} from "react-redux";
import photo from "../../../../../assets/images/photo_absence.svg";

const Artifact = styled.div`
    position: relative;
    background: ${p => p.color};
    border-radius: 15px;
    height: 100%;
`
const Img = styled.img({
    width: '100%',
    borderRadius: '15px 15px 0 0',
    height: 205,
    objectFit: 'cover',
    marginRight: 20,
    display: 'block',
})
const ImgContainer = styled.div`
    width: 100%;
    border-radius: 15px 15px 0 0;
    background-color: #f3f3f3;
    display: flex;
    align-items: center;
    height: 205px;
    justify-content: center;
`
const ImgAbsence = styled.img`
    display: block;
    width: 80px;
    height: 80px;
`
const Content = styled.div`
    padding: 20px 20px 54px;
`
const Title = styled.div`
    margin-bottom: 10px;
    color: ${p => p.color};
    font-size: 18px;
    font-weight: 600;
`
const Button = styled.button<{color: string, border: string, backgroundColor: string}>`
    color: ${p => p.color};
    padding: 8px 11px;
    border: ${p => p.border};
    border-radius: 10px;
    background-color: ${p => p.backgroundColor};
    cursor: pointer;
    display: block;
    position: absolute;
    bottom: 20px;
    left: 20px;
`
const Span = styled.span``

const ArtifactPrintItem:FC<{active: boolean, artifact:ArtifactItemType}> = React.memo(({active, artifact}) => {
    const dispatch = useDispatch()

    const handleSubmit = () => {
        if(!active) {
            dispatch(actions.setSelectedItemId(artifact))
        }
        else {
            dispatch(actions.deleteCurrentItem(artifact))
        }
    }

    return (
        <Artifact color={active ? 'rgba(0, 206, 132, 0.1)' : 'rgba(8, 29, 63, 0.03)'}>
            {
                artifact?.images.length > 0 &&
                artifact?.images[0].img_compressed_resized !== null
                ?
                <Img src={artifact?.images[0].img_compressed_resized} alt='artifact' />
                :
                <ImgContainer>
                    <ImgAbsence src={photo} alt='artifact' />
                </ImgContainer>
            }
            <Content>
                <Title color={active ? '#00AE70' : '#081D3F'}>
                    ID: {artifact.id}
                </Title>
                <Title color={active ? '#00AE70' : '#081D3F'}>
                    {artifact.name}
                </Title>
            </Content>
                <Button color={active ? 'white' : '#4A6375'} border={active ? '1px solid #00CE84' : '1px solid #4A6375'}
                        backgroundColor = {active ? '#00CE84' : 'transparent'} onClick={() => handleSubmit()}>
                    {
                        !active
                            ? <Span>Добавить к печати</Span>
                            : <Span>Убрать из печати</Span>
                    }
                </Button>
        </Artifact>
    );
})

export default ArtifactPrintItem;