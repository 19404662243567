import React, {FC, FunctionComponent, SVGProps} from 'react';
import s from "./PrintHeaderNavItem.module.scss";
import {NavLink, useRouteMatch} from "react-router-dom";
import {PrintSectionsEnum} from "../../../../../../types/Types";

const PrintHeaderNavItem:FC<MyProps> = ({section, link, Ico, label}) => {
    const match = useRouteMatch<MatchParams>();
    return (
        <NavLink to={link} className={s.link} activeClassName={s.active}>
            <Ico fill={match.params.section === section ? '#7764F5' : '#081D3F' } />
            {label}
        </NavLink>
    );
};

export default PrintHeaderNavItem;

type MyProps = {
    section: PrintSectionsEnum
    link: string
    Ico: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined; }>
    label: string
}

type MatchParams = {
    section: PrintSectionsEnum
}