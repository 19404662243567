import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import Print from "./Print/Print";
import Error404 from "../404/Error404";

const AdminContainer = () => {
    return (
        <div className={'outer'}>
            <Switch>
                <Route exact path='/print' render={() => <Redirect to={'/print/tickets'} />} />
                <Route exact path='/print/:section' render={() => <Print />} />
                <Route exact path='/' render={() => <Redirect to={'/print/tickets'} />} />
                <Route exact path='*' render={() => <Error404 />} />
            </Switch>
        </div>
    );
};

export default AdminContainer;