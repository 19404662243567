import React, {FC, useEffect, useRef} from 'react';
import s from './AudioItem.module.scss'
import './Audio.css'
import AudioPlayer, {RHAP_UI} from "react-h5-audio-player";
import {ArtifactAudioType} from "../../types/Types";

const AudioItem:FC<MyProps> = React.memo(({audio, toggleAudio, index, playingIndex}) => {
    const PlayerRef = useRef<any>(null);
    useEffect(() => {
        if(PlayerRef.current !== null) {
            if(playingIndex !== index) {
                PlayerRef.current.audio.current.pause()
            }
        }
    }, [playingIndex])

    return (
        <div className={s.container}>
            <div className={s.name}>
                {audio.name}
            </div>
            <AudioPlayer ref={PlayerRef}
                         className={s.audio}
                         volume={0.1}
                         onPlay={(e) => {toggleAudio(index)}}
                         src={audio.audio}
                         showJumpControls={false}
                         customProgressBarSection={[
                             RHAP_UI.MAIN_CONTROLS,
                             RHAP_UI.CURRENT_TIME, RHAP_UI.PROGRESS_BAR, RHAP_UI.DURATION
                         ]}
                         customControlsSection={[]}
                         preload={"metadata"}
                         autoPlay={false}
                         autoPlayAfterSrcChange={false}
            />
        </div>
    );
});

export default AudioItem;

type MyProps = {
    audio: ArtifactAudioType
    index: number
    playingIndex: number
    toggleAudio:(index: number) => void
}
