import React from 'react';
import s from './Welcome.module.scss'
import logo from '../../../../assets/images/Logo.svg'
import welcome from '../../../../assets/images/welcome.svg'
import main_help from "../../../../assets/images/main_help.svg";
import welcome_map from "../../../../assets/images/welcome_map.svg";
import SmallBtn from "../../../../common/SmallBtn/SmallBtn";
import welcome_qr from "../../../../assets/images/welcome_qr.svg";
import FilledSmallBtn from "../../../../common/FilledSmallBtn/FilledSmallBtn";

const Welcome = () => {
    return (
        <div className={s.container}>
            <img className={s.logo} src={logo} alt='logo' />
            <div className={s.line}></div>
            <div className={s.description}>
                Сервис для удобного просмотра экспонатов музеев, выставок или экскурсий
            </div>
            <div className={s.welcomeContainer}>
                <img src={welcome} alt='welcome' />
            </div>

            <div className={s.navigation}>
                <div className={s.helpBtn}>
                    <SmallBtn img={main_help} text={'Помощь'} link={'/information'} />
                </div>
                <div className={s.qrBtn}>
                    <SmallBtn img={welcome_qr} text={'Скан QR'} link={'/qr_scan'} />
                </div>
                <div className={s.expositionsBtn}>
                    <FilledSmallBtn img={welcome_map} text={'Посещенные выставки'} link={'/history'} />
                </div>
            </div>

        </div>
    );
};

export default Welcome;