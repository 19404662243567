import React from 'react';
import img500 from '../../assets/images/500.svg'
import ErrorPage from "../../common/ErrorPage/ErrorPage";

const Error500 = () => {
    return (
        <>
            <ErrorPage img={img500} error={500} text={'Увы, но наш сервер решил отдохнуть!\n' +
            'Пожалуйста, обратитесь к администратору'} />
        </>
    );
};

export default Error500;