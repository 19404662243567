import React, {useState, useEffect} from 'react';
import s from './Login.module.scss'
import loginIco from '../../assets/images/login.svg'
import padlockIco from '../../assets/images/login_password.svg'
import logo from '../../assets/images/Logo.svg'
import {useDispatch, useSelector} from "react-redux";
import {authActions, login} from "../../redux/auth-reducer";
import {AppStateType} from "../../redux/redux-store";
import {NavLink, Redirect} from "react-router-dom";
import LoginInput from "./LoginInput/LoginInput";
import LoginError from "./LoginError/LoginError";

const Login = () => {
    const dispatch = useDispatch()
    const [isError, setIsError] = useState(false)
    const [isEmpty, setIsEmpty] = useState(false)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const isUserLogin = useSelector((state:AppStateType) => state.auth.isLogin)
    const isLoginError = useSelector((state:AppStateType) => state.auth.isLoginError)

    useEffect(() => {
        dispatch(authActions.logout())
        localStorage.removeItem('accessToken')
        return () => {
            dispatch(authActions.setIsUserLogin(false))
        }
    }, [dispatch])

    useEffect(() => {
        if(isLoginError) {
            setIsError(true)
        }
    }, [isLoginError])

    const handleSubmit = () => {
        dispatch(authActions.setLoginError(false))
        if(username !== '' && password !== '') {
            dispatch(login(username, password))
        }
        else {
            setIsEmpty(true)
        }
    }
    const handleKeyUp = (e:any) => {
        if(e.keyCode === 13) {
            handleSubmit()
        }
    }

    if (isUserLogin) {
        return <Redirect exact to='/print' />
    }

    return (
        <div className={s.container}>
            <NavLink to='/' className={s.logo}>
                <img src={logo} alt='logo' />
            </NavLink>
            <div className={s.line}></div>
            <div className={s.title}>
                Панель администратора
            </div>
            <LoginInput handleKeyUp={handleKeyUp} value={username} setIsEmpty={setIsEmpty} setIsError={setIsError} setValue={setUsername} img={loginIco} placeholder='Имя пользователя' id='login' />
            <LoginInput handleKeyUp={handleKeyUp} value={password} type={'password'} setIsEmpty={setIsEmpty} setIsError={setIsError} setValue={setPassword} img={padlockIco} placeholder={'Пароль'} id='password'/>
            <button onClick={handleSubmit} className={s.button}>
                Войти
            </button>
            <LoginError isError={isEmpty} text='Пожалуйста, заполните все поля' />
            <LoginError isError={isError} text='Неправильный логин или пароль' />
        </div>
    );
};

export default Login;