import React, {useEffect, useState} from 'react';
import s from './Map.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {AppStateType} from "../../../redux/redux-store";
import {getMapList} from "../../../redux/map-reducer";
import {MapType} from "../../../types/Types";
import {Redirect, useHistory, useRouteMatch} from "react-router-dom";
import AloneLogoTop from "../../../common/AloneLogoTop/AloneLogoTop";
import arrow_back from "../../../assets/images/arrow_back.svg";
import blue from "../../../assets/images/current_circle.svg";
import red from "../../../assets/images/visited_circle.svg";
import green from "../../../assets/images/unvisited_circle.svg";
import Preloader from "../../../common/Preloader/Preloader";
import MapImage from "./MapImage/MapImage";

const Map = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [isError, setIsError] = useState(false)
    const isFetch = useSelector((state: AppStateType) => state.app.isFetch)
    const token = useSelector((state: AppStateType) => state.app.token)
    const roomsList = useSelector((state: AppStateType) => state.map.roomsList)
    const expositionMapImage = useSelector((state: AppStateType) => state.map.expositionMapImage)
    const exposition = useSelector((state: AppStateType) => state.exposition.exposition)
    const match = useRouteMatch<MatchType>()

    //готовый список комнат для отображения на карте
    const [roomsMapList, setRoomsMapList] = useState<Array<MapType>>([])

    useEffect(() => {
        const visited_rooms = localStorage.getItem('visited_rooms')
        //Если нет списка посещенных комнат в памяти приложения
        if(visited_rooms === null) {
            //Если айдишник больше 0, он есть число и пришел список комнат
            if(Number(match.params.id) > 0 && !isNaN(Number(match.params.id)) && roomsList.length > 0) {
                localStorage.setItem('visited_rooms', JSON.stringify([Number(match.params.id)]))
                //Менять в массиве комнат поле isVisited здесь!
                const list = roomsList.map((room: MapType) => room.id === Number(match.params.id) ?
                    ({
                        ...room,
                        isCurrentRoom: true,
                        isVisited: true,
                    })
                    :({...room}))
                setRoomsMapList(list)
            }
            //если пришел массив комнат и в урле нет айдишника
            else if(roomsList.length > 0 && match.params.id === undefined) {
                setRoomsMapList(roomsList)
            }
        }

        //Если список комнат пришел
        else {
            //массив айдишников посещенных комнат
            const rooms = JSON.parse(visited_rooms)
            if(roomsList.length > 0) {
                //Если в урле есть айдишник
                if(!isNaN(Number(match.params.id))) {
                    //Есть ли текущая комната в массиве, добавляем, если нет
                    const currentRoom = rooms.find((roomId: number) => roomId === Number(match.params.id))
                    if(!currentRoom) {
                        localStorage.setItem('visited_rooms', JSON.stringify([...rooms, Number(match.params.id)]))
                        rooms.push(Number(match.params.id)) //Пушим в массив с айдишниками айди текущей комнаты
                    }
                }
                const list = roomsList.map((room: MapType) => rooms.includes(room.id) && room.id === Number(match.params.id) ?
                    ({
                        ...room,
                        isVisited: true,
                        isCurrentRoom: true
                    })
                    :( rooms.includes(room.id) ?
                            ({
                                ...room,
                                isVisited: true,
                                isCurrentRoom: false
                            })
                            : ({...room})
                    )
                )
                setRoomsMapList(list)
            }
        }

    }, [roomsList, match.params.id])

    useEffect(() => {
        let id
        let exp_id = Number(localStorage.getItem('exposition_id'))
        if(exposition.id === undefined && exp_id !== null && !isNaN(exp_id)) {
            id = exp_id
        }
        else if(exposition.id) {
            id = exposition.id
        }

        if(id !== undefined) {
            dispatch(getMapList(token, id))
        }
        else {
            setIsError(true)
        }
    }, [dispatch,match.params.id, token, exposition])

    if(!exposition.id && localStorage.getItem('exposition_id') === null && token === '') {
        return <Redirect to='/' />
    }
    if(isFetch || roomsMapList.length === 0) {
        return <Preloader />
    }
    if(isError) {
        return <Redirect to='/not_found' />
    }

    return (
        <div className={s.container}>
            <div className={s.top}>
                <AloneLogoTop />
                <div className='pageTitle'>
                    <button onClick={() => history.go(-1)}>
                        <img src={arrow_back} alt='arrow_back' />
                    </button>
                    Карта выставки
                </div>
            </div>
            <div style={{maxHeight: 400*(window.innerHeight/1000)}} className={s.map}>
                {
                    expositionMapImage !== null ?
                    <MapImage rooms={roomsMapList} image={expositionMapImage} />
                    :
                        <div className={s.notFound}>
                            Карта не найдена
                        </div>
                }
            </div>
            <div className={s.rule}>
                Перемещайте карту для обзора всей выставки и переходите в комнату по нажатию на неё
            </div>
            <div className={s.legendContainer}>
                <div className={s.legend}>
                    <div className={s.title}>Легенда</div>
                    <div className={s.legendItem}>
                        <img src={blue} alt='current_room' />
                        <span>Текущая комната</span>
                    </div>
                    <div className={s.legendItem}>
                        <img src={green} alt='visited_room' />
                        <span>Посещенные комнаты</span>
                    </div>
                    <div className={s.legendItem}>
                        <img src={red} alt='unvisited_room' />
                        <span>Непосещенные комнаты</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Map;

type MatchType = {
    id: string,
}