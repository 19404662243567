import React, {FC} from 'react';
import s from "./ExpositionInfo.module.scss";
import {ExpositionType} from "../../../../types/Types";
import ExpositionTicket from "./ExpositionTicket/ExpositionTicket";

const ExpositionInfo:FC<MyProps> = ({exposition, exposition_type, ticket_remaining_lifetime}) => {
    return (
        <div className={s.descriptionContainer}>
            <div className={s.title}>
                Что посмотреть
            </div>
            <div className={s.wrapperBorder}>
            </div>
            <div className={s.description}>
                {exposition.description}
            </div>
            <ExpositionTicket exposition={exposition} exposition_type={exposition_type} ticket_remaining_lifetime={ticket_remaining_lifetime} />
        </div>
    );
};

export default ExpositionInfo;

type MyProps = {
    exposition: ExpositionType
    exposition_type: 'По билетам' | 'Свободный вход' | ''
    ticket_remaining_lifetime: string
}