import {BaseThunkType, InferActionsTypes} from "./redux-store";
import {expositionAPI} from "../api/exposition-api";
import {ExpositionType} from "../types/Types";
import {appActions, AppActionsType} from "./app-reducer";
import {StatusCodesEnum} from "../api/api";
import {
    addExpositionItemInArray,
    removeExpositionInfo,
    removeExpositionItemFromArray,
    setExpositionInfo
} from "../utils/utils";

export type InitialStateType = typeof initialState
let initialState = {
    exposition: {} as ExpositionType,
    ticket_remaining_lifetime: '' as string,
    exposition_id: 0 as number,
    exposition_name: '' as string,
    exposition_type: '' as 'По билетам' | 'Свободный вход' | '',
}

const expositionReducer = (state = initialState, action: ExpositionActionsType):InitialStateType  => {
    switch (action.type) {
        case 'AW/EXPOSITION/EXPOSITION_DATA_RECEIVED':
            return {
                ...state,
                exposition: action.payload.exposition,
                ticket_remaining_lifetime: action.payload.ticket_remaining_lifetime,
            }
        case 'AW/EXPOSITION/EXPOSITION_PARAMS_RECEIVED':
            return {
                ...state,
                exposition_id: action.payload.exposition_id,
                exposition_name: action.payload.exposition_name,
                exposition_type: action.payload.exposition_type,
            }

        default:
            return state;
    }
}

export type ExpositionActionsType = InferActionsTypes<typeof expositionActions>

export const expositionActions = {
    expositionDataReceived: (exposition: ExpositionType, ticket_remaining_lifetime: string) =>
        ({type: 'AW/EXPOSITION/EXPOSITION_DATA_RECEIVED', payload: {exposition, ticket_remaining_lifetime}} as const),
    setExpositionParams: (exposition_id: number, exposition_name: string, exposition_type: 'По билетам' | 'Свободный вход' | '') =>
        ({type: 'AW/EXPOSITION/EXPOSITION_PARAMS_RECEIVED', payload: {exposition_id, exposition_name, exposition_type}} as const),
}

type ThunkType = BaseThunkType<ExpositionActionsType | AppActionsType>

export const getExpositionData = (token: string, exposition_id: number, exposition_type: 'По билетам' | 'Свободный вход'): ThunkType => {
    return async (dispatch) => {
        dispatch(appActions.toggleIsFetching(true))
        try {
            let data = await expositionAPI.getExpositionData(token, exposition_id, exposition_type)
            console.log('getExpositionData', data)
            if(!data.status) {
                dispatch(expositionActions.expositionDataReceived(data.exposition, data.ticket_remaining_lifetime))
                dispatch(expositionActions.setExpositionParams(data.exposition.id, data.exposition.name, data.exposition.type))
                if(data.exposition.type === 'Свободный вход') {
                    dispatch(appActions.tokenReceived(''))
                    localStorage.removeItem('token')
                }
                setExpositionInfo(data.exposition.id, data.exposition.type, data.exposition.name)
                addExpositionItemInArray(token, data.exposition.id, data.exposition.type, data.exposition.name)
            }
            else if(data.status === StatusCodesEnum.Forbidden) {
                if(data.message && data.message === 'Object is archived') {
                    dispatch(appActions.setArchived(true))
                }
                else {
                    dispatch(appActions.setForbiddenError(true))
                }
                removeExpositionItemFromArray(token, exposition_id, exposition_type)
            }
            else if(data.status === StatusCodesEnum.NotFound) {
                removeExpositionItemFromArray(token, exposition_id, exposition_type)
                removeExpositionInfo()
                dispatch(appActions.setNotFoundError(true))
            }
            else if(data.status === StatusCodesEnum.BadRequest) {
                removeExpositionItemFromArray(token, exposition_id, exposition_type)
                removeExpositionInfo()
                dispatch(appActions.setServerError(true))
            }
            dispatch(appActions.toggleIsFetching(false))
        }
        catch (e: any) {
            console.error('getExpositionData', e.config)
            dispatch(appActions.toggleIsFetching(false))
            dispatch(appActions.setServerError(true))
        }
    }
}

export default expositionReducer;