import React, {FC} from 'react';
import s from './LoginInput.module.scss'

const LoginInput:FC<MyProps> = ({handleKeyUp, setIsError, setIsEmpty, setValue, value, type= 'text', img, id, placeholder}) => {
    return (
        <div className={s.input}>
            <label htmlFor={value}>
                <img src={img} alt={value} />
            </label>
            <input onKeyUp={handleKeyUp} type={type} id={id} value={value} onChange={(e) => {
                setIsEmpty(false)
                setIsError(false)
                setValue(e.target.value)
            }} placeholder={placeholder} />
        </div>
    );
};

export default LoginInput;

type MyProps = {
    id: string
    handleKeyUp: (e: any) => void
    value: string
    placeholder: string
    img: string
    setIsEmpty: (isEmpty: boolean) => void
    setIsError: (isError: boolean) => void
    setValue: (value: string) => void
    type?: string
}