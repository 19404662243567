import React, {FC, useState} from 'react';
import s from './Enter.module.scss'
import AloneLogoTop from "../../../common/AloneLogoTop/AloneLogoTop";
import EnterFooter from "./EnterFooter/EnterFooter";
import EnterId from "./EnterId/EnterId";

const Enter:FC = () => {
    const [inputId, setInputId] = useState('')
    const [error, setError] = useState(false)
    const [isButtonDisable, setIsButtonDisable] = useState(true)

    const splitString = (value: string) => value.split('_')

    const handleChange = (value: string) => {
        setInputId(value)
        const arrayOfStrings = splitString(value)
        //Если после сплита
        if(!(Number(arrayOfStrings[0]) === 1 || Number(arrayOfStrings[0]) === 2 ) && arrayOfStrings[0] !== '') {
            setIsButtonDisable(true)
            setError(true)
        }
        else if (arrayOfStrings[0] === "") {
            setError(false)
        }
        else if(arrayOfStrings.length === 2) {
            //Проверка, что в массиве первое число - это 1 или 2, а второе - просто число
            if((Number(arrayOfStrings[0]) === 1 || Number(arrayOfStrings[0]) === 2 ) &&
                (!isNaN(Number(arrayOfStrings[1])) && arrayOfStrings[1] !== '') ) {
                setIsButtonDisable(false)
                setError(false)
            }
            else if(arrayOfStrings[1] !== '') {
                setIsButtonDisable(true)
                setError(true)
            }
        }
        else if (arrayOfStrings.length > 2) {
            setIsButtonDisable(true)
            setError(true)
        }
    }
    return (
        <div className={s.container}>
            <div className={s.top}>
                <AloneLogoTop />
                <EnterId inputId={inputId} handleChange={handleChange} />
                {
                    error ?
                        <div className={'error'}>
                            Комната или экспонат с таким идентификатором не найдены
                        </div>
                        :
                        <div className={s.empty}> </div>
                }
            </div>
            <EnterFooter isButtonDisable={isButtonDisable} splitString={splitString} inputId={inputId} />
        </div>
    );
};

export default Enter;