import React from 'react';
import s from './ExpositionNavigation.module.scss'
import SmallBtn from "../../../../common/SmallBtn/SmallBtn";
import main_input_id from "../../../../assets/images/main_input_id.svg";
import main_help from "../../../../assets/images/main_help.svg";
import main_map from "../../../../assets/images/main_map.svg";
import FilledSmallBtn from "../../../../common/FilledSmallBtn/FilledSmallBtn";
import main_qr from "../../../../assets/images/main_qr.svg";

const ExpositionNavigation = () => {
    return (
        <div className={s.navigation}>
            <SmallBtn img={main_input_id} text={'Ввод ID'} link={'/enter'} />
            <SmallBtn img={main_help} text={'Помощь'} link={'/information'} />

            <div className={s.map}>
                <SmallBtn img={main_map} text={'Карта'} link={'/map'} />
            </div>

            <div className={s.qrBtn}>
                <FilledSmallBtn img={main_qr} text={'Скан QR'} link={'/qr_scan'} />
            </div>
            {/*<div className={s.icoBtn}>*/}
            {/*    <BorderedSmallBtn img={main_ico} text={'Скан ICO'} link={'/ico_scan'} />*/}
            {/*</div>*/}
        </div>
    );
};

export default ExpositionNavigation;