import React, {FC, useEffect, useState} from 'react';
import s from './Room.module.scss'
import AloneLogoTop from "../../../common/AloneLogoTop/AloneLogoTop";
import {NavLink, Redirect, useRouteMatch} from "react-router-dom";
import arrow_back from "../../../assets/images/arrow_back.svg";
import where from "../../../assets/images/where_to_go.svg";
import {useDispatch, useSelector} from "react-redux";
import {AppStateType} from "../../../redux/redux-store";
import {actions, getRoomData} from "../../../redux/room-reducer";
import Preloader from "../../../common/Preloader/Preloader";
import Description from "../../../common/Description/Description";
import RoomImage from "./RoomImage/RoomImage";
import RoomArtifacts from "./RoomArtifacts/RoomArtifacts";

const Room: FC = () => {
    const [isError, setIsError] = useState(false)
    const dispatch = useDispatch()

    const roomData = useSelector((state: AppStateType) => state.room.roomData)
    const isFetch = useSelector((state: AppStateType) => state.app.isFetch)
    const token = useSelector((state: AppStateType) => state.app.token)
    const match = useRouteMatch<MatchType>()

    //Затирание информации о комнате
    useEffect(() => {
        return () => {
            dispatch(actions.roomDataErased())
        }
    }, [dispatch])

    //Получение информации о комнате
    useEffect(() => {
        if(!isNaN(Number(match.params.id))) {
            dispatch(getRoomData(token, Number(match.params.id)))
        }
        else {
            setIsError(true)
        }
    }, [dispatch, match.params.id, token])

    //Обновление списка комнат в памяти приложения
    useEffect(() => {
        //Если пришла информация по комнате
        if(roomData.id) {
            const visited_rooms = localStorage.getItem('visited_rooms')
            //В случае самого первого визита на страницу комнаты
            if(visited_rooms === null) {
                if(Number(match.params.id) !== 0 && !isNaN(Number(match.params.id))) {
                    localStorage.setItem('visited_rooms', JSON.stringify([Number(match.params.id)]))
                }
            }
            else {
                const rooms = JSON.parse(visited_rooms)
                const asd = rooms.find((roomId: number) => roomId === Number(match.params.id))
                if(!asd) {
                    localStorage.setItem('visited_rooms', JSON.stringify([...rooms, Number(match.params.id)]))
                }
            }
        }
    }, [roomData, match.params.id])
    if(isError) {
        return <Redirect to='not_found' />
    }
    if(isFetch) {
        return <Preloader />
    }
    return (
        <div className={s.container}>
            <div className={s.top}>
                <AloneLogoTop />
                <div className='pageTitle'>
                    <NavLink to={'/map'}>
                        <img src={arrow_back} alt='arrow_back' />
                    </NavLink>
                    {roomData.name}
                </div>
                <RoomImage roomData={roomData} />
                <Description description={roomData?.description} isMarkdown={roomData.description_is_markdown} />
                <RoomArtifacts roomData={roomData} />
            </div>

            <div className={s.button}>
                <NavLink className={s.link} to={`/connected_rooms/${roomData.id}`}>
                    <img src={where} alt='double arrow' />
                    <span>Куда дальше?</span>
                </NavLink>
            </div>
        </div>
    );
};

export default Room;

type MatchType = {
    id: string,
}