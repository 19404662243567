import {BaseThunkType, InferActionsTypes} from "./redux-store";
import {RoomType} from "../types/Types";
import {roomAPI} from "../api/room-api";
import {appActions, AppActionsType} from "./app-reducer";
import {StatusCodesEnum} from "../api/api";
import {expositionActions, ExpositionActionsType} from "./exposition-reducer";
import {
    addExpositionItemInArray,
    removeExpositionInfo,
    removeExpositionItemFromArray,
    setExpositionInfo
} from "../utils/utils";

export type InitialStateType = typeof initialState
let initialState = {
    roomData: {} as RoomType,
}

//reducer возвращает стейт такого же значения, что и принимает, чтобы мы в кейсах не дописывали новые поля объекту
const roomReducer = (state = initialState, action: ActionsType):InitialStateType  => {
    switch (action.type) {
        case 'AW/ROOM/ROOM_DATA_RECEIVED':
            return {
                ...state,
                roomData: action.payload.roomData,
            }
        case 'AW/ROOM/ROOM_DATA_ERASED':
            return {
                ...state,
                roomData: {} as RoomType,
            }

        default:
            return state;
    }
}

type ActionsType = InferActionsTypes<typeof actions>

export const actions = {
    roomDataReceived: (roomData: RoomType) =>
        ({type: 'AW/ROOM/ROOM_DATA_RECEIVED', payload: {roomData}} as const),
    roomDataErased: () =>
        ({type: 'AW/ROOM/ROOM_DATA_ERASED', payload: {}} as const),
}

type ThunkType = BaseThunkType<ActionsType | AppActionsType | ExpositionActionsType>

export const getRoomData = (token: string, id: number): ThunkType => {
    return async (dispatch) => {
        dispatch(appActions.toggleIsFetching(true))
        try {
            let data = await roomAPI.getRoomData(token, id)
            console.log('getRoomData', data)
            if(!data.status) {
                dispatch(actions.roomDataReceived(data.room))
                setExpositionInfo(data.exposition_id, data.exposition_type,data.exposition_name)
                addExpositionItemInArray(token, data.exposition_id, data.exposition_type, data.exposition_name)
                dispatch(expositionActions.setExpositionParams(data.exposition_id, data.exposition_name, data.exposition_type))
            }
            else if(data.status === StatusCodesEnum.Forbidden) {
                if(data.message && data.message === 'Object is archived') {
                    dispatch(appActions.setArchived(true))
                }
                else {
                    removeExpositionItemFromArray(token, Number(localStorage.getItem('exposition_id')), localStorage.getItem('exposition_type') as 'Свбодный вход' & 'По билетам')
                    dispatch(appActions.setForbiddenError(true))
                }
            }
            else if(data.status === StatusCodesEnum.NotFound) {
                dispatch(appActions.setNotFoundError(true))
            }
            else if(data.status === StatusCodesEnum.BadRequest) {
                removeExpositionItemFromArray(token, Number(localStorage.getItem('exposition_id')), localStorage.getItem('exposition_type') as 'Свбодный вход' & 'По билетам')
                removeExpositionInfo()
                dispatch(appActions.setServerError(true))
            }
            dispatch(appActions.toggleIsFetching(false))
        }
        catch (e: any) {
            console.error('getRoomData', e.config)
            dispatch(appActions.toggleIsFetching(false))
            dispatch(appActions.setServerError(true))
        }
    }
}

export default roomReducer;