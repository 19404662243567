import React, {FC, useEffect, useState} from 'react';
import ImageGallery from "react-image-gallery";
import './PhotoSlider.scss'
import {renderLeftNav} from "./renderLeftNav";
import {renderRightNav} from "./renderRightNav";
import {ArtifactsImagesType} from "../../types/Types";
import {renderFullScreen} from "./renderFullScreen";

const PhotoSlider: FC<MyProps> = ({images}) => {

    const [originalImages, setOriginalImages] = useState<Array<{original: string, img: string}>>([])

    useEffect(() => {
        if(images?.length > 0) {
            const mappedImages = images.map((image: ArtifactsImagesType) => {
                return {
                    ...image,
                    original: window.innerWidth > 650 ? image.img : image.img_compressed_resized
                }
            })
            setOriginalImages(mappedImages)
        }
    }, [images])

    return (
        <>
            <ImageGallery
                items={originalImages}
                showPlayButton={false}
                showThumbnails={false}
                lazyLoad={true}
                renderFullscreenButton={renderFullScreen}
                renderLeftNav={renderLeftNav}
                renderRightNav={renderRightNav}
            />
        </>
    );
};

export default PhotoSlider;

type MyProps = {
    images: Array<ArtifactsImagesType>
}