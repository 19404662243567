import React, {FC, useEffect, useState} from 'react';
import s from "./LinkItem.module.scss";
import link_icon from '../../assets/images/artifact_link.svg'
import {ArtifactLinkType} from "../../types/Types";

const LinkItem: FC<{link: ArtifactLinkType}> = ({link}) => {

    const [correctLink, setCorrectLink] = useState('')

    useEffect(() => {
        if(link.link.includes('http')) {
            setCorrectLink(link.link)
        }
        else {
            setCorrectLink('https://' + link.link)
        }
    }, [correctLink])

    return (
        <a href={correctLink} className={s.link} rel={"noreferrer noopener"} target='_blank'>
            <div className={s.info}>
                <div className={s.name}>
                    {link.name}
                </div>
                <div className={s.src}>
                    {link.link}
                </div>
            </div>
            <div className={s.imgContainer}><img src={link_icon} alt='link' /></div>
        </a>
    );
};

export default LinkItem;