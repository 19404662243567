import {ErrorResponseType, baseURL} from "./api";
import axios from "axios";
import {AliveTicketType, ArtifactItemType, PrintRoomType} from "../types/Types";

export type GetAliveTicketsType = {
    exposition_name: string,
    alive_tickets: Array<AliveTicketType>
}
export type GetRoomsListType = {
    exposition_name: string,
    rooms: Array<PrintRoomType>
}

export type GetArtifactsListType = {
    artifacts: Array<ArtifactItemType>,
    exposition_name: string,
    count: number,
    numpages: number,
    nextlink: string,
    prevlink: string,
}


export const printAPI = {
    getAliveTickets(find_by_letters: string) {
        let formdata = new FormData();
        formdata.append('find_by_letters', find_by_letters);
        const accessToken = 'Bearer ' + localStorage.getItem('accessToken')
        return axios.post<GetAliveTicketsType & ErrorResponseType>(baseURL + `api/print/tickets`, formdata,
            {
                headers: {
                    'Authorization': `${accessToken}`
                },
            })
            .then(response => response.data)
    },
    getRoomsList(find_by_letters: string) {
        let formdata = new FormData();
        formdata.append('find_by_letters', find_by_letters);
        const accessToken = 'Bearer ' + localStorage.getItem('accessToken')
        return axios.post<GetRoomsListType& ErrorResponseType>(baseURL + `api/print/rooms`, formdata,
            {
                headers: {
                    'Authorization': `${accessToken}`
                },
            })
            .then(response => response.data)
    },
    getArtifactsList(find_by_letters: string, pageNumber: number = 1, are_icons_exist: boolean) {
        let formdata = new FormData();
        formdata.append('find_by_letters', find_by_letters);
        formdata.append('are_icons_exist', are_icons_exist ? 'true' : 'false');
        const accessToken = 'Bearer ' + localStorage.getItem('accessToken')
        return axios.post<GetArtifactsListType & ErrorResponseType>(baseURL + `api/print/artifacts?page=${pageNumber}`, formdata,
            {
                headers: {
                    'Authorization': `${accessToken}`
                },
            })
            .then(response => response.data)
    },
}
