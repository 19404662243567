import React, {MouseEventHandler} from "react";
import cl from "classnames";
import s from "./PhotoSlider.module.scss";
import fullscreen from "../../assets/images/fullscreen.svg";

export const renderFullScreen = (onClick: MouseEventHandler<HTMLButtonElement>) => {
    return (
        <button
            className={cl(s.fullscreen)}
            onClick={onClick}>
            <div>
                <img src={fullscreen} alt='fullscreen' />
            </div>
        </button>
    )
}