import React, {FC} from 'react'
import s from './AloneLogoTop.module.scss'
import logo from '../../assets/images/Logo.svg'
import { NavLink } from 'react-router-dom'

const AloneLogoTop: FC = () => {
    return (
        <NavLink to='/' className={s.link}>
            <img className={s.logo} src={logo} alt='logo' />
        </NavLink>
    )
}

export default AloneLogoTop