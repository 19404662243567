import React, {FC} from 'react';
import styled from "styled-components";
import photo from '../../../../../assets/images/photo_absence.svg'
import {PrintRoomType} from "../../../../../types/Types";
import {actions} from "../../../../../redux/print-reducer";
import {useDispatch} from "react-redux";

const Room = styled.div`
    background: ${p => p.color};
    border-radius: 15px;
    padding: 20px 20px 54px;
    position: relative;
    height: 100%;
`
const Content = styled.div({
    display: 'flex',
    marginBottom: 20,
    alignItems: 'center',
    gap: 20,
})

const Img = styled.img({
    width: 110,
    height: 110,
    borderRadius: '50%',
    objectFit: 'cover',
})

const ImgContainer = styled.div`
    min-width: 110px;
    height: 110px;
    background-color: #f3f3f3;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
`
const ImgAbsence = styled.img`
    display: block;
    width: 80px;
    height: 80px;
`
const Id = styled.div`
    margin-bottom: 10px;
    color: ${p => p.color};
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.1em;
`
const Title = styled.div`
    width: 100%;
    flex-basis: 110px;
    margin-bottom: 10px;
    color: ${p => p.color};
    font-size: 18px;
    font-weight: 600;
    max-width: 325px;
`

const Button = styled.button<{color: string, border: string, backgroundColor: string}>`
    color: ${p => p.color};
    padding: 8px 11px;
    border: ${p => p.border};
    border-radius: 10px;
    background-color: ${p => p.backgroundColor};
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    left: 20px;
`
const Span = styled.span``
const Div = styled.div``

const RoomPrintItem:FC<{active: boolean, room: PrintRoomType}> = React.memo(({active, room}) => {
    const dispatch = useDispatch()
    const handleSubmit = () => {
        if(!active) {
            dispatch(actions.setSelectedItemId(room))
        }
        else {
            dispatch(actions.deleteCurrentItem(room))
        }
    }

    return (
        <Room color={active ? 'rgba(0, 206, 132, 0.1)' : 'rgba(8, 29, 63, 0.03)'}>
            <Content>
                {
                    room?.img_compressed_resized !== null ?
                    <Img src={room.img_compressed_resized} alt='room' />
                    :
                    <ImgContainer>
                        <ImgAbsence src={photo} alt='room' />
                    </ImgContainer>
                }
                <Div>
                    <Id color={active ? '#00AE70' : '#081D3F'}>
                        ID: {room.id}
                    </Id>
                    <Title color={active ? '#00AE70' : '#081D3F'}>
                        {room.name}
                    </Title>
                </Div>
            </Content>
            <Button color={active ? 'white' : '#4A6375'} border={active ? '1px solid #00CE84' : '1px solid #4A6375'}
                    backgroundColor = {active ? '#00CE84' : 'transparent'} onClick={() => handleSubmit()}>
                {
                    !active
                        ? <Span>Добавить к печати</Span>
                        : <Span>Убрать из печати</Span>
                }
            </Button>
        </Room>
    );
})

export default RoomPrintItem;
