import React, {FC, useEffect, useState} from 'react';
import s from '../Rooms/Rooms.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {AppStateType} from "../../../../redux/redux-store";
import {actions, getArtifactsList} from "../../../../redux/print-reducer";
import TitleItem from "../TitleItem/TitleItem";
import {ReactComponent as Artifact} from "../../../../assets/images/print_artifact.svg";
import Search from "../../../../common/Search/Search";
import {ArtifactItemType} from "../../../../types/Types";
import ArtifactItems from "./ArtifactItems/ArtifactItems";
import PrintTypeBtn from "../../../../common/PrintTypeBtn/PrintTypeBtn";

const Artifacts:FC<MyProps> = ({expositionName, printType, togglePrintTypes}) => {
    const dispatch = useDispatch()
    const [letters, setLetters] = useState('')
    const [isIcoExists, setIsIcoExists] = useState(false)
    const currentPage = useSelector((state: AppStateType) => state.print.currentPage)

    useEffect(() => {
        return () => {
            dispatch(actions.artifactsListReceived(expositionName, [] as Array<ArtifactItemType>, 0))
            dispatch(actions.setCurrentPage(1))
            dispatch(actions.removeSelectedItems())
        }
    }, [dispatch])

    useEffect(() => {
        dispatch(getArtifactsList(letters, currentPage, isIcoExists))

    }, [dispatch, isIcoExists])

    useEffect(() => {
        if(printType === 'qr+ico' || printType === 'ico') {
            setIsIcoExists(true)
        }
        else if(printType === 'qr') {
            setIsIcoExists(false)
        }
    }, [printType])

    const handleKeyUp = (e:any) => {
        if(e.keyCode === 13) {
            dispatch(getArtifactsList(letters, 1, isIcoExists))
        }
    }

    const onPageChanged = (pageNumber: number) => { // Поиск по новой странице + изменение текущей
        dispatch(getArtifactsList(letters, pageNumber, isIcoExists))
    }

    const handleSubmit = () => {
        dispatch(getArtifactsList(letters, 1, isIcoExists))
    }

    return (
        <>
            <div className={s.top}>
                <div className={s.name}>
                    <TitleItem Ico={Artifact} title='Экспонаты' />
                    <div className={s.exposition}>
                        Выставка
                        «{expositionName}»
                    </div>
                </div>
                <Search handleSubmit={handleSubmit} handleKeyUp={handleKeyUp} setLetters={setLetters} letters={letters} />
            </div>
            <PrintTypeBtn printType={printType} constPrintType={'qr'} togglePrintTypes={togglePrintTypes} text={'Печатать QR'}/>
            {/*<PrintTypeBtn printType={printType} constPrintType={'qr+ico'} togglePrintTypes={togglePrintTypes} text={'Печатать QR и ICO'}/>*/}
            {/*<PrintTypeBtn printType={printType} constPrintType={'ico'} togglePrintTypes={togglePrintTypes} text={'Печатать ICO'}/>*/}

            <ArtifactItems onPageChanged={onPageChanged}
            />
        </>
    );
};

export default Artifacts;

type MyProps = {
    printType: string,
    togglePrintTypes: (printType: string) => void
    expositionName: string,
}