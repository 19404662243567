import {Action, applyMiddleware, combineReducers, createStore} from "redux";
import thunkMiddleware, {ThunkAction}from 'redux-thunk';
import expositionReducer from "./exposition-reducer";
import roomReducer from "./room-reducer";
import connectedRoomsReducer from "./connectedRooms-reducer";
import appReducer from "./app-reducer";
import mapReducer from "./map-reducer";
import artifactReducer from "./artifact-reducer";
import printReducer from "./print-reducer";
import authReducer from "./auth-reducer";
import icoReducer from "./ico-reducer";

let rootReducer  = combineReducers({
    app: appReducer,
    exposition: expositionReducer,
    room: roomReducer,
    connected: connectedRoomsReducer,
    map: mapReducer,
    auth: authReducer,
    artifact: artifactReducer,
    print: printReducer,
    ico: icoReducer,
});

type RootReducerType = typeof rootReducer //(globalState: AppStateType) => AppStateType
export type AppStateType = ReturnType<RootReducerType>

let store = createStore(rootReducer , applyMiddleware(thunkMiddleware));

//key - это название action creator, a U - это выводимый тип функции
// type PropertiesTypes= T extends {[key: string]:
export type InferActionsTypes<T> = T extends {[key: string]: (...args: any[]) => infer U } ? U : never
export type BaseThunkType<A extends Action, R = Promise<void>> = ThunkAction<R, AppStateType, unknown, A>

if(process.env.NODE_ENV === 'development') {
    // @ts-ignore
    window.store = store;
}

export default store;
