import React from 'react';
import './PreloaderLogo.css'
import mirea from './../../assets/images/MIREA_Gerb_Colour.png'
import laba from './../../assets/images/lab.svg'
import realityX from './../../assets/images/RealityX.svg'
import cross from './../../assets/images/preloader_logo_cross.svg'

type MyProps = {}
type MyState = {
    class: 'transition' | 'trans' | 'display'
}

class PreloaderLogo extends React.Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);
        this.state = {
            class: "transition",
        }
        this.changeClass = this.changeClass.bind(this)
    }

    changeClass() {
        setTimeout(() =>
        {
            this.setState({

                class: 'trans'
            })
        }, 700)

        setTimeout(() =>
        {
            this.setState({
                class: 'display'
            })
        }, 1700)
    }

    componentDidMount() {
        this.changeClass()
    }

    render() {
        return (
            <>
                <div className={`${this.state.class}`}>

                    <img className='mireaImg' src={mirea} alt="mirea logo preloader"/>
                    <img className='crossImg' src={cross} alt="cross"/>
                    <div className='preloaderImagesContainer'>
                        <img className='laba' src={laba} alt='RTU IT Lab' />
                        <img className='reality' src={realityX} alt='RealityX' />
                    </div>
                </div>
            </>
        );
    }

}

export default PreloaderLogo;