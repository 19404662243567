import React, {FC} from 'react';
import styled from "styled-components";
import {Bottom, Cross, LabLarge, LogoLarge, Rule} from '../Common/CommonStyled';
import logo_color from '../../../assets/images/print_logo_color.png'
import lab from '../../../assets/images/lab.svg'
import cross from '../../../assets/images/ticket_white_cross.svg'
import {PrintRoomType} from "../../../types/Types";
import {baseURL} from "../../../api/api";

const QRCode = require('qrcode.react');

const TicketLayout = styled.div`
    background-color: #F2F3F7;
    //width: 565px;
    //height: 798px;
    width: 792px;
    height: 1120px;
    position: relative;
    margin: 0 auto;
`
const Id = styled.div({
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
    color: '#7764F5',
    fontSize: 38,
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: 30,
})
const Content = styled.div`
      padding: 100px 75px 0;
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 0 auto 70px;
    `
const QrContainer = styled.div`
      padding: 25px;
      width: 550px;
      height: 550px;
      background-color: white;
    `
const Name = styled.div({
    textAlign: 'center',
    color: '#7764F5',
    fontSize: 34,
    fontWeight: 600,
    marginBottom: 30
})

const LargeQr:FC<{item: PrintRoomType, section: string}> = React.memo(({item, section}) => {

    return (
        <TicketLayout>
            <Content>
                <Name>{item.name}</Name>
                <QrContainer>
                    <QRCode
                        value={baseURL + (section === 'rooms' ? `rooms/${item.id}` : `artifacts/${item.id}`)}
                        size={500}
                    />
                </QrContainer>
                <Rule>Отсканируйте QR-код</Rule>
                {
                    section === 'rooms' &&
                    <Id>ID: 2_{item.id}</Id>
                }
                {
                    section === 'artifacts' &&
                    <Id>ID: 1_{item.id}</Id>
                }
                <Bottom>
                    <LogoLarge src={logo_color} alt='logo' />
                    <Cross src={cross} alt='cross' />
                    <LabLarge src={lab} alt='lab' />
                </Bottom>
            </Content>
        </TicketLayout>
    );
});

export default LargeQr;
