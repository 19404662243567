import React, {FC} from 'react';
import s from './PrintHeaderNav.module.scss'
import logo from "../../../../../assets/images/Logo.svg";
import {ReactComponent as Ticket} from "../../../../../assets/images/print_ticket.svg";
import {ReactComponent as Room} from "../../../../../assets/images/print_room.svg";
import {ReactComponent as Artifact} from "../../../../../assets/images/print_artifact.svg";
import PrintHeaderNavItem from "./PrintHeaderNavItem/PrintHeaderNavItem";

const PrintHeaderNav:FC = () => {
    return (
        <nav className={s.nav}>
            <div className={s.logo}>
                <img src={logo} alt='logo' />
            </div>
            <div className={s.linksContainer}>
                <PrintHeaderNavItem section='tickets' link='/print/tickets' Ico={Ticket} label='Билеты' />
                <PrintHeaderNavItem section='rooms' link='/print/rooms' Ico={Room} label='Комнаты' />
                <PrintHeaderNavItem section='artifacts' link='/print/artifacts' Ico={Artifact} label='Экспонаты' />
            </div>
        </nav>
    );
};

export default PrintHeaderNav;