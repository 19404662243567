import React, {FC, useEffect, useState} from 'react';
import s from './ArtifactImagesSwiper.module.scss'
import {useSelector} from "react-redux";
import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode} from "swiper";
import {AppStateType} from "../../../../redux/redux-store";
import ArtifactsImageSwiperItem from './ArtifactsImageSwiperItem/ArtifactsImageSwiperItem';
import {ArtifactSwiperItemType} from "../../../../types/Types";
import "swiper/css/free-mode";
import {getUnvisitedItems, getVisitedItems} from "../../../../utils/utils";
import cl from 'classnames'

const ArtifactImagesSwiper:FC<MyProps> = ({step}) => {

    const artifactSwiperItems = useSelector((state: AppStateType) => state.artifact.artifactSwiperItems)
    const artifactData = useSelector((state: AppStateType) => state.artifact.artifactData)
    const windowSize = window.innerWidth > 480 ? 480 : window.innerWidth
    const [visitedArtifacts, setVisitedArtifacts] = useState<Array<ArtifactSwiperItemType>>([])
    const [unvisitedArtifacts, setUnvisitedArtifacts] = useState<Array<ArtifactSwiperItemType>>([])

    useEffect(() => {
        const visited_artifacts = localStorage.getItem('visited_artifacts')

        if( artifactData.id) {
            if(visited_artifacts !== null) {
                //Массив айдишников посещенных экспонатов
                const artifacts = JSON.parse(visited_artifacts)

                //Есть ли текущий экспонат в массиве, добавляем, если нет
                const currentArtifacts = artifacts.find((artifactId: number) => artifactId === Number(artifactData.id))
                if(!currentArtifacts) {
                    localStorage.setItem('visited_artifacts', JSON.stringify([...artifacts, artifactData.id]))
                    artifacts.push(artifactData.id)
                }

                //Список посещенных экспонатов
                const visitedArtifacts = getVisitedItems(artifactSwiperItems, artifacts)
                setVisitedArtifacts(visitedArtifacts)
                //Список непосещенных экспонатов
                const unvisitedArtifacts = getUnvisitedItems(artifactSwiperItems, artifacts)
                setUnvisitedArtifacts(unvisitedArtifacts)

            }
            else {
                localStorage.setItem('visited_artifacts', JSON.stringify([artifactData.id]))
                const artifacts = [artifactData.id]

                //Список посещенных экспонатов
                const visitedArtifacts = getVisitedItems(artifactSwiperItems, artifacts)
                setVisitedArtifacts(visitedArtifacts)
                //Список непосещенных экспонатов
                const unvisitedArtifacts = getUnvisitedItems(artifactSwiperItems, artifacts)
                setUnvisitedArtifacts(unvisitedArtifacts)
            }
        }

    }, [artifactSwiperItems])

    return (
        <div className={s.container}>
            <div className={s.inner}>
                <div className={cl('blinking-shadow', 'right-shadow')}></div>
                <div className={cl('blinking-shadow', 'left-shadow')}></div>
                <Swiper
                    slidesPerView={(windowSize - 10*(Math.floor(windowSize / 50) - 1)) / 50}
                    modules={[FreeMode]}
                    freeMode={true}
                    grabCursor={true}
                >
                    {
                        unvisitedArtifacts.length > 0 &&
                        unvisitedArtifacts.map((item: ArtifactSwiperItemType) => (
                            <SwiperSlide key={item.id}>
                                <ArtifactsImageSwiperItem step={step} img={item.img_compressed_resized}
                                                          id={item.id} isCurrent={item.isCurrent} />
                            </SwiperSlide>
                            )
                        )
                    }
                    {
                        visitedArtifacts.length > 0 &&
                        visitedArtifacts.map((item: ArtifactSwiperItemType) => (
                            <SwiperSlide key={item.id}>
                                <ArtifactsImageSwiperItem isVisited={true} step={step} img={item.img_compressed_resized}
                                                          id={item.id} isCurrent={item.isCurrent} />
                            </SwiperSlide>
                            )
                        )
                    }
                </Swiper>
            </div>
        </div>
    );
};

export default ArtifactImagesSwiper;

type MyProps = {
    step: number
}