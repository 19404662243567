import React, {FC} from 'react';
import s from './RoomImage.module.scss'
import PhotoAbsence from "../../../../common/PhotoAbsence/PhotoAbsence";
import {RoomType} from "../../../../types/Types";

const RoomImage:FC<MyProps> = ({roomData}) => {
    return (
        <div className={s.imgContainer}>
            {
                roomData.img !== null
                    ?
                    <picture>
                        <source media='(max-width: 650px)' srcSet={roomData.img_compressed_resized} />
                        <img src={roomData.img} alt='room' />
                    </picture>
                    :
                    <PhotoAbsence />
            }
        </div>
    );
};

export default RoomImage;

type MyProps = {
    roomData: RoomType
}