import React, {FC} from 'react';
import s from './InformationStep.module.scss'
import cl from "classnames";

const InformationStep:FC<{step: number}> = ({step}) => {
    return (
        <>
            {
                step !== 5 &&
                <div className={s.stepContainer}>
                    <div className={s.points}>
                        <div className={cl(s.point, step >= 1 && s.active)}></div>
                        <div className={cl(s.point, step >= 2 && s.active)}></div>
                        <div className={cl(s.point, step >= 3 && s.active)}></div>
                        <div className={cl(s.point, step >= 4 && s.active)}></div>
                        <div className={cl(s.point)}></div>
                    </div>
                    <div className={s.step}>
                        {step} / 5
                    </div>
                </div>
            }
        </>
    );
};

export default InformationStep;