import styled from "styled-components";

export const OneContainer = styled.div`
      text-align: center;
      grid-template-columns: 1fr;
      grid-gap: 0;
      display: grid;
      width: 792px;
      height: 1122px;
      margin: auto;
    `
export const TwoContainer = styled.div`
      text-align: center;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0;
      display: grid;
      width: 792px;
      height: 1122px;
      margin: auto;
    `
export const FourContainer = styled.div`
      text-align: center;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 396px;
      grid-gap: 0;
      width: 792px;
      height: 1122px;
      margin: auto;
    `
export const Bottom = styled.div`
      display: flex;
      align-items: center;
    `
export const Cross = styled.img`
      width: 7px;
      display: block;
    `
export const LogoLarge = styled.img`
      margin-right: 12px;
      display: block;
      width: 100px;
    `
export const LabLarge = styled.img`
      display: block;
      margin-left: 18px;
      width: 35px;
    `
export const Img = styled.img``
export const Div = styled.div``

export const Rule = styled.div`
      margin: 15px 0 35px;
      text-align: center;
      font-size: 18px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-weight: 700;
      color: rgba(8, 29, 63, 0.6);
    `
export const RuleMediumQrIco = styled.div`
      margin: 10px 0 15px;
      text-align: center;
      font-size: 10px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-weight: 700;
      color: rgba(8, 29, 63, 0.6);
    `
export const RuleMedium = styled.div`
      margin: 0 0 20px;
      text-align: center;
      font-size: 12px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-weight: 700;
      color: rgba(8, 29, 63, 0.6);
    `

export const ContentMedium = styled.div`
      padding: 29px 20px 0;
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 0 auto;
    `
export const ContentSmall = styled.div`
      padding: 20px 20px 0;
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 0 auto;
    `

export const CrossMediumQrIco = styled.img`
      width: 4px;
      display: block;
    `
export const LogoMediumQrIco = styled.img`
      margin-right: 6px;
      display: block;
      width: 58px;
    `
export const LabMediumQrIco = styled.img`
      display: block;
      margin-left: 8px;
      width: 24px;
    `
export const CrossMedium = styled.img`
      width: 4px;
      display: block;
    `
export const LogoMedium = styled.img`
      margin-right: 7px;
      display: block;
      width: 70px;
    `
export const LabMedium = styled.img`
      display: block;
      margin-left: 10px;
      width: 29px;
    `
export const CrossSmall = styled.img`
      width: 3px;
      display: block;
    `
export const LogoSmall = styled.img`
      margin-right: 4px;
      display: block;
      width: 50px;
    `
export const LabSmall = styled.img`
      display: block;
      margin-left: 7px;
      width: 20px;
    `