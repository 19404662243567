import React, {FC} from 'react';
import {ArtifactLinkType} from "../../../../types/Types";
import LinkItem from "../../../../common/LinkItem/LinkItem";

const ArtifactLinks:FC<MyProps> = ({links}) => {
    return (
        <>
            {
                links?.length > 0 &&
                <div>
                    {
                        links.map((link: ArtifactLinkType) => {
                            return <LinkItem key={link.link} link={link} />
                        })
                    }
                </div>
            }
        </>
    );
};

export default ArtifactLinks;

type MyProps = {
    links: Array<ArtifactLinkType>
}