import {BaseThunkType, InferActionsTypes} from "./redux-store";
import {appActions, AppActionsType} from "./app-reducer";
import {icoAPI} from "../api/ico-api";
import {StatusCodesEnum} from "../api/api";

export type InitialStateType = typeof initialState
let initialState = {
    ico_id: null as number | null,
    result_id: null as number | null,
    result_type: '' as 'artifact' | 'room' | '',
    isNotFoundIco: false as boolean,
}

const icoReducer = (state = initialState, action: ActionsType):InitialStateType  => {
    switch (action.type) {
        case 'AW/ICO/ICO_URL_RECEIVED':
            return {
                ...state,
                ico_id: action.payload.ico_id,
                result_id: action.payload.result_id,
                result_type: action.payload.result_type,
            }
        case 'AW/ICO/ICO_URL_REMOVE':
            return {
                ...state,
                ico_id: null,
                result_id: null,
                result_type: '',
            }
        case 'AW/ICO/SET_NOT_FOUND_ERROR':
            return {
                ...state,
                isNotFoundIco: action.payload.isNotFoundIco,
            }
        default:
            return state;
    }
}

type ActionsType = InferActionsTypes<typeof icoActions>

export const icoActions = {
    icoUrlReceived: (ico_id: number, result_id: number, result_type: 'artifact' | 'room') =>
        ({type: 'AW/ICO/ICO_URL_RECEIVED', payload: {ico_id, result_id, result_type}} as const),
    icoUrlRemove: () =>
        ({type: 'AW/ICO/ICO_URL_REMOVE', payload: {}} as const),
    setNotFoundError: (isNotFoundIco: boolean) =>
        ({type: 'AW/ICO/SET_NOT_FOUND_ERROR', payload: {isNotFoundIco}} as const),

}

type ThunkType = BaseThunkType<ActionsType | AppActionsType>

export const getIcoUrl = (token: string, img_base64: string): ThunkType => {
    return async (dispatch) => {
        dispatch(appActions.toggleIsFetching(true))
        try {
            let data = await icoAPI.getIco(token, img_base64)
            console.log('getIcoUrl', data)
            if(data.status === StatusCodesEnum.Success) {
                dispatch(icoActions.icoUrlReceived(data.ico_id, data.result_id, data.result_type))
            }
            else if (data.status === StatusCodesEnum.NotFound) {
                dispatch(icoActions.setNotFoundError(true))
            }
            else {
                throw new Error()
            }
            dispatch(appActions.toggleIsFetching(false))
        }
        catch (e: any) {
            console.error('getIcoUrl', e.config)
            dispatch(appActions.toggleIsFetching(false))
        }
    }
}

export default icoReducer;