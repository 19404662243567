import React, {FC} from 'react';
import styled from "styled-components";
import {AliveTicketType} from "../../../../../types/Types";
import {useDispatch} from "react-redux";
import {actions} from "../../../../../redux/print-reducer";

const Ticket = styled.div<{color: string}>`
    background: ${p => p.color};
    border-radius: 15px;
    padding: 20px;
`
const Name = styled.div<{color: string}>`
    border-radius: 15px;
    color: ${p => p.color}
    font-size: 18px;
    font-weight: 600;
`
const Time = styled.div<{color: string}>`
    color: ${p => p.color}
    opacity: 0.5;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px
`
const Button = styled.button<{color: string, border: string, backgroundColor: string}>`
    color: ${p => p.color};
    padding: 8px 11px;
    border: ${p => p.border};
    border-radius: 10px;
    background-color: ${p => p.backgroundColor};
    cursor: pointer;
`
const Span = styled.span``

const TicketPrintItem:FC<{active: boolean, ticket: AliveTicketType}> = React.memo( ({active, ticket}) => {
    const dispatch = useDispatch()
    const date = new Date(ticket.created_at)

    const handleSubmit = () => {
        if(!active) {
            dispatch(actions.setSelectedItemId(ticket))
        }
        else {
            dispatch(actions.deleteCurrentItem(ticket))
        }
    }

    return (
        <div>
            <Ticket color={active ? 'rgba(0, 206, 132, 0.1)' : 'rgba(8, 29, 63, 0.03)'}>
                <Name color={active ? '#00AE70' : '#081D3F'}>Билет {ticket.id}</Name>
                <Time color={active ? '#008253' : '#4A6375'}>Дата создания {date.toLocaleDateString()} {date.toLocaleTimeString([], {timeStyle: 'short'})}</Time>
                <Button color={active ? 'white' : '#4A6375'} border={active ? '1px solid #00CE84' : '1px solid #4A6375'}
                        backgroundColor = {active ? '#00CE84' : 'transparent'} onClick={() => handleSubmit()}>
                    {
                        !active
                        ? <Span>Добавить к печати</Span>
                        : <Span>Убрать из печати</Span>
                    }
                </Button>
            </Ticket>
        </div>
    );
})

export default TicketPrintItem;