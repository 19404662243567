import {ErrorResponseType, baseURL, ExpositionItemsType} from "./api";
import axios from "axios";
import {RoomType} from "../types/Types";

type GetRoomDataResponseType = {
    room: RoomType
}

export const roomAPI = {
    getRoomData(token: string, id: number) {
        let formdata = new FormData();
        formdata.append('token', token);
        return axios.post<GetRoomDataResponseType & ExpositionItemsType & ErrorResponseType>(baseURL + `api/rooms/${id}`, formdata)
            .then(response => response.data)
    },
}
