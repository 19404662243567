import React, {FC} from 'react';
import {ExpositionParametersType} from "../../../../types/Types";
import VisitedExpositionsItem from "./VisitedExpositionsItem/VisitedExpositionsItem";
import s from "./VisitedItems.module.scss";

const VisitedItems:FC<MyProps> = ({expositions, handleSelect}) => {
    return (
        <>
            {
                (expositions &&
                    expositions.length > 0)
                    ? expositions.map((item: ExpositionParametersType) => {
                        return <VisitedExpositionsItem key={item.exposition_id} exposition_id={item.exposition_id}
                                                       exposition_name={item.exposition_name} exposition_type={item.exposition_type}
                                                       token={item.token} handleSelect={handleSelect} />
                    })
                    :
                    <div className={s.notFound}>
                        Вы пока не посетили ни одной выставки
                    </div>
            }
        </>
    );
};

export default VisitedItems;

type MyProps = {
    expositions: Array<ExpositionParametersType>
    handleSelect: (token: string, id: number, type: 'Свободный вход' | 'По билетам', name: string) => void
}