import {ErrorResponseType, baseURL, ExpositionItemsType} from "./api";
import axios from "axios";
import {MapType} from "../types/Types";

type MapResponseType = {
    rooms: Array<MapType>
    exposition_map_image: string | null
}

export const mapAPI = {
    getMapList(token: string, exposition_id: number) {
        let formdata = new FormData();
        formdata.append('token', token);
        return axios.post<MapResponseType & ExpositionItemsType & ErrorResponseType>(baseURL + `api/map/${exposition_id}`, formdata)
            .then(response => response.data)
    },
}
