import {ErrorResponseType, baseURL, ExpositionItemsType} from "./api";
import axios from "axios";
import {ConnectedRoomType} from "../types/Types";

type GetConnectedRoomsListResponseType = {
    connected_rooms: Array<ConnectedRoomType>,
    room: ConnectedRoomType,
}


export const connectedRoomsAPI = {
    getConnectedRoomsList(token: string, id: number) {
        let formdata = new FormData();
        formdata.append('token', token);
        return axios.post<GetConnectedRoomsListResponseType & ExpositionItemsType & ErrorResponseType>(baseURL + `api/connected_rooms/${id}`, formdata)
            .then(response => response.data)
    },
}