import React, {FC} from 'react';
import s from './ExpositionTicket.module.scss'
import {ExpositionType} from "../../../../../types/Types";

const ExpositionTicket:FC<MyProps> = ({exposition_type, exposition, ticket_remaining_lifetime}) => {
    return (
        <>
            {
                (exposition_type === 'По билетам' || exposition.type === 'По билетам') &&
                <div className={s.ticketRemaining}>
                    Ваш билет активен еще <span>{ticket_remaining_lifetime}</span>
                </div>
            }
            {
                exposition_type === 'Свободный вход' &&
                <div className={s.ticketRemaining}>
                    Ваш билет активен
                </div>
            }
        </>
    );
};

export default ExpositionTicket;

type MyProps = {
    exposition: ExpositionType
    exposition_type: 'По билетам' | 'Свободный вход' | ''
    ticket_remaining_lifetime: string
}