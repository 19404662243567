import React, {FC} from 'react';
import s from "./ArtifactItems.module.scss";
import {ArtifactItemType} from "../../../../../types/Types";
import ArtifactPrintItem from "../ArtifactPrintItem/ArtifactPrintItem";
import Paginator from "../../../../../common/Paginator/Paginator";
import {useSelector} from "react-redux";
import {AppStateType} from "../../../../../redux/redux-store";
import Preloader from "../../../../../common/Preloader/Preloader";

const ArtifactItems:FC<MyProps> = ({onPageChanged}) => {
    const currentPage = useSelector((state: AppStateType) => state.print.currentPage)
    const selectedItems = useSelector((state: AppStateType) => state.print.selectedItems)
    const artifacts = useSelector((state: AppStateType) => state.print.artifacts)
    const count = useSelector((state:AppStateType) => state.print.count)
    const pageSize = useSelector((state:AppStateType) => state.print.pageSize)
    const isFetch = useSelector((state: AppStateType) => state.app.isFetch)

    if(isFetch) {
        return <Preloader />
    }

    return (
        <>
            {
                artifacts?.length > 0
                    ?
                    <div className={s.artifacts}>
                        {
                            artifacts.map((artifact:ArtifactItemType) => {
                                return (
                                    <div key={artifact.id}>
                                        {
                                            selectedItems.some((item) => item.id === artifact.id) ?
                                                <ArtifactPrintItem active={true} artifact={artifact} />
                                                :
                                                <ArtifactPrintItem active={false} artifact={artifact} />
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                    :
                    <div className={s.notFound}>
                        Экспонаты не найдены
                    </div>
            }
            <Paginator totalItemsCount={count}
                       portionSize={5}
                       pageSize={pageSize}
                       currentPage={currentPage}
                       onPageChanged={onPageChanged} />
        </>
    );
};

export default ArtifactItems;

type MyProps = {
    onPageChanged: (pageNumber: number) => void,
}