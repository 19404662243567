import React, {FC} from 'react';
import { NavLink } from 'react-router-dom';
import s from './ArtifactItem.module.scss'
import {ArtifactItemType} from "../../../../../types/Types";
import {ReactComponent as Arrow} from "../../../../../assets/images/arrow_right.svg";
import PhotoAbsence from "../../../../../common/PhotoAbsence/PhotoAbsence";

const ArtifactItem: FC<{artifact: ArtifactItemType}> = ({artifact}) => {
    return (
        <NavLink to={`/artifacts/${artifact.id}`} className={s.container}>
            <div className={s.imgContainer}>
                {
                    artifact.images.length > 0 &&
                    artifact.images[0].img !== null
                    ?
                        <picture>
                            <source media='(max-width: 650px)' srcSet={artifact.images[0].img_compressed_resized} />
                            <img src={artifact.images[0].img} alt='artifact' />
                        </picture>
                    :
                    <PhotoAbsence />
                }
            </div>
            <div className={s.info}>
                <div className={s.name}>
                    {artifact.name}
                </div>
                <div className={s.link}>
                    <span>Перейти</span>
                    <Arrow fill='#7764F5' />
                </div>
            </div>
        </NavLink>
    );
};

export default ArtifactItem;