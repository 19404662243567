import React, {FC} from 'react';
import s from './ConfirmButtonModal.module.scss'

const ConfirmButtonModal:FC<{confirmChangeIco:() => void}> = ({confirmChangeIco}) => {
    return (
        <div className={s.modal}>
            <div className={s.title}>
                Подтвердите действие
            </div>
            <div className={s.text}>
                После совершения данного действия список для печати будет очищен
            </div>
            <button onClick={confirmChangeIco} className={s.confirmBtn}>
                Подтвердить
            </button>
        </div>
    );
};

export default ConfirmButtonModal;