import {ExpositionParametersType, MapType} from "../types/Types";


//Фильтрация списков соседних комнат от архвированных
export const getFilteredFromArchivedRooms = (rooms: Array<MapType>, arrayOfRoomsIds: Array<number>) => {
    return rooms.map((item: MapType) => ({
        ...item,
        connected_rooms: item.connected_rooms.filter((con: number) => arrayOfRoomsIds.includes(con))
    }))
}
//Список посещенных комнат/экспонатов
export const getUnvisitedItems = (itemsArray: Array<any>, visitedIdsArray: Array<number>) => {
    //Проверяем включает ли в себя массив айдишников посещенных комнат - айдишник соединенных комнат
    return itemsArray.filter((item: any) => !visitedIdsArray.includes(item.id))
}
//Список непосещенных комнат/экспонатов
export const getVisitedItems = (itemsArray: Array<any>, visitedIdsArray: Array<number>) => {
    return itemsArray.filter((item: any) => visitedIdsArray.includes(item.id))
}

//Добавление информации по текущей выставке в локальное хранилище
export const setExpositionInfo = (exposition_id: number, exposition_type: 'По билетам' | 'Свободный вход', exposition_name: string) => {
    localStorage.setItem('exposition_id', String(exposition_id))
    localStorage.setItem('exposition_type', String(exposition_type))
    localStorage.setItem('exposition_name', String(exposition_name))
}

//Удаление информации по текущей выставке в локальном хранилище
export const removeExpositionInfo = () => {
    localStorage.removeItem('exposition_id')
    localStorage.removeItem('exposition_type')
    localStorage.removeItem('exposition_name')
    localStorage.removeItem('token')
}

//Добавление массива выставок в локальное хранилище
export const addExpositionItemInArray = (token: string, exposition_id: number, exposition_type: 'По билетам' | 'Свободный вход', exposition_name: string) => {
    const expositionsFromLocalStorage = localStorage.getItem('expositions')
    if (expositionsFromLocalStorage === null) {
        localStorage.setItem('expositions', JSON.stringify([{token: token, exposition_id: exposition_id, exposition_type: exposition_type, exposition_name: exposition_name}]))
    }
    else {
        const expositionArrayFromLocalStorage = JSON.parse(expositionsFromLocalStorage)
        if(exposition_type === 'По билетам') {
            const filteredArray = expositionArrayFromLocalStorage.filter((exposition: ExpositionParametersType) => exposition.token !== token)
            filteredArray.push({token: token, exposition_id: exposition_id, exposition_type: exposition_type, exposition_name: exposition_name})
            localStorage.setItem('expositions', JSON.stringify(filteredArray))
        }
        else {
            const filteredArray = expositionArrayFromLocalStorage.filter((exposition: ExpositionParametersType) => exposition.exposition_id !== exposition_id)
            filteredArray.push({token: token, exposition_id: exposition_id, exposition_type: exposition_type, exposition_name: exposition_name})
            localStorage.setItem('expositions', JSON.stringify(filteredArray))
        }
    }
}

//Фильтрация массива выставок
export const removeExpositionItemFromArray = (token: string, exposition_id: number, exposition_type: 'По билетам' | 'Свободный вход') => {
    const expositionsFromLocalStorage = localStorage.getItem('expositions')
    if (expositionsFromLocalStorage !== null) {
        const expositionArrayFromLocalStorage = JSON.parse(expositionsFromLocalStorage)
        //Фильтрация по токену, если выставка по билетам
        if(exposition_type === 'По билетам') {
            const filteredArray = expositionArrayFromLocalStorage.filter((exposition: ExpositionParametersType) => exposition.token !== token)
            localStorage.setItem('expositions', JSON.stringify(filteredArray))
        }
        else {
            const filteredArray = expositionArrayFromLocalStorage.filter((exposition: ExpositionParametersType) => exposition.exposition_id !== exposition_id)
            localStorage.setItem('expositions', JSON.stringify(filteredArray))
        }
    }
}