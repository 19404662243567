export const baseURL = process.env.REACT_APP_PRODUCTION_URL || window.location.origin + "/";
// alert(baseURL)

export enum StatusCodesEnum {
    Success = 200,
    NotFound = 404,
    BadRequest = 400,
    Forbidden = 403,
    ServerError = 500,
}

export type ErrorResponseType<M = '', RC = StatusCodesEnum> = {
    message: M
    status: RC
}

export type ExpositionItemsType = {
    exposition_id: number,
    exposition_name: string,
    exposition_type: 'По билетам' | 'Свободный вход',
}