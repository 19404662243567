import React, {FC, useEffect} from 'react';
import './App.scss';
import './common/style.scss'
import 'github-markdown-css/github-markdown-light.css'
import 'react-lazy-load-image-component/src/effects/blur.css';
import Container from './components/Container/Container'
import Login from "./components/Login/Login";
import {Route, Switch} from "react-router-dom";
import Error404 from "./components/404/Error404";
import Error403 from "./components/403/Error403";
import {useDispatch, useSelector} from "react-redux";
import {AppStateType} from "./redux/redux-store";
import AdminContainer from "./components/Admin/AdminContainer";
import {authActions} from "./redux/auth-reducer";
import {getAliveTickets} from "./redux/print-reducer";
import Error500 from "./components/500/Error500";
import Archive from "./components/Archive/Archive";
import Preloader from "./common/Preloader/Preloader";

const App:FC = () => {
    const dispatch = useDispatch()
    const isAuth = useSelector((state:AppStateType) => state.auth.isAuth)
    const isAdminInitialize = useSelector((state: AppStateType) => state.auth.isAdminInitialize)

    useEffect(() => {
        const access = localStorage.getItem('accessToken')
        if (access !== null) {
            dispatch(getAliveTickets(''))
        }
        else {
            dispatch(authActions.setIsAdminInitialize(true))
        }
    }, [dispatch])

    if(!isAdminInitialize) {
        return <Preloader />
    }

    return (
        <Switch>
            <Route exact path='/login' render={() => <Login />} />
            <Route exact path='/archive' render={() => <Archive />} />
            <Route exact path='/not_found' render={() => <Error404 />} />
            <Route exact path='/canceled' render={() => <Error403 />} />
            <Route exact path='/server_error' render={() => <Error500 />} />
            {
                isAuth
                ? <AdminContainer />
                : <Container />
            }
        </Switch>
    );

}

export default App
