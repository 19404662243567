import {InferActionsTypes} from "./redux-store";

export type InitialStateType = typeof initialState
let initialState = {
    isFetch: false as boolean,
    isInitialized: false as boolean,
    token: '' as string,
    notFoundError: false as boolean,
    forbiddenError: false as boolean,
    serverError: false as boolean,
    isArchived: false as boolean,
}

const appReducer = (state = initialState, action: AppActionsType):InitialStateType  => {
    switch (action.type) {
        case 'AW/APP/TOGGLE_IS_FETCHING':
            return {
                ...state,
                isFetch: action.payload.isFetch
            }
        case 'AW/APP/INITIALIZE_APP':
            return {
                ...state,
                isInitialized: action.payload.isInitialized
            }
        case 'AW/APP/TOKEN_RECEIVED':
            return {
                ...state,
                token: action.payload.token
            }
        case 'AW/APP/SET_ERRORS_FALSE':
            return {
                ...state,
                notFoundError: false,
                forbiddenError: false,
                serverError: false,
            }
        case 'AW/APP/SET_NOT_FOUND_ERROR':
            return {
                ...state,
                notFoundError: action.payload.notFoundError,
            }
        case 'AW/APP/SET_FORBIDDEN_ERROR':
            return {
                ...state,
                forbiddenError: action.payload.forbiddenError,
            }
        case 'AW/APP/SET_SERVER_ERROR':
            return {
                ...state,
                serverError: action.payload.serverError,
            }
        case 'AW/APP/SET_ARCHIVED':
            return {
                ...state,
                isArchived: action.payload.isArchived,
            }

        default:
            return state;
    }
}

export type AppActionsType = InferActionsTypes<typeof appActions>

export const appActions = {
    toggleIsFetching: (isFetch: boolean) =>
        ({type: 'AW/APP/TOGGLE_IS_FETCHING', payload: {isFetch}} as const),
    initializeApp: (isInitialized: boolean) =>
        ({type: 'AW/APP/INITIALIZE_APP', payload: {isInitialized}} as const),
    tokenReceived: (token: string) =>
        ({type: 'AW/APP/TOKEN_RECEIVED', payload: {token}} as const),
    setErrorsFalse: () =>
        ({type: 'AW/APP/SET_ERRORS_FALSE', payload: {}} as const),
    setNotFoundError: (notFoundError: boolean) =>
        ({type: 'AW/APP/SET_NOT_FOUND_ERROR', payload: {notFoundError}} as const),
    setForbiddenError: (forbiddenError: boolean) =>
        ({type: 'AW/APP/SET_FORBIDDEN_ERROR', payload: {forbiddenError}} as const),
    setServerError: (serverError: boolean) =>
        ({type: 'AW/APP/SET_SERVER_ERROR', payload: {serverError}} as const),
    setArchived: (isArchived: boolean) =>
        ({type: 'AW/APP/SET_ARCHIVED', payload: {isArchived}} as const),

}

export default appReducer;