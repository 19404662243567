import {BaseThunkType, InferActionsTypes} from "./redux-store";
import {ArtifactsImagesType, ArtifactSwiperItemType, ArtifactType} from "../types/Types";
import {appActions, AppActionsType} from "./app-reducer";
import {artifactAPI} from "../api/artifact-api";
import {StatusCodesEnum} from "../api/api";
import {expositionActions, ExpositionActionsType} from "./exposition-reducer";
import {
    addExpositionItemInArray,
    removeExpositionInfo,
    removeExpositionItemFromArray,
    setExpositionInfo
} from "../utils/utils";

export type InitialStateType = typeof initialState
let initialState = {
    artifactSwiperItems: [] as Array<ArtifactSwiperItemType>,
    artifactData: {} as ArtifactType,
}

const artifactReducer = (state = initialState, action: ActionsType):InitialStateType  => {
    switch (action.type) {
        case 'AW/ARTIFACT/ARTIFACTS_LIST_RECEIVED':
            return {
                ...state,
                artifactData: action.payload.artifacts.filter((item: ArtifactType) => item.is_current)[0],
                artifactSwiperItems: action.payload.artifacts.map((item: ArtifactType) => ({
                    ...item,
                    id: item.id,
                    isCurrent: item.is_current,
                    img: item.images[0]?.img,
                    img_compressed_resized: item.images[0]?.img_compressed_resized,
                }))
            }
        case 'AW/ARTIFACT/ARTIFACT_DATA_ERASED':
            return {
                ...state,
                // artifactSwiperItems: [] as Array<ArtifactSwiperItemType>,
                artifactData: {} as ArtifactType,
            }
        default:
            return state;
    }
}

type ActionsType = InferActionsTypes<typeof actions>

export const actions = {
    artifactsListReceived: (artifacts: Array<ArtifactType>) =>
        ({type: 'AW/ARTIFACT/ARTIFACTS_LIST_RECEIVED', payload: {artifacts}} as const),
    artifactDataErased: () =>
        ({type: 'AW/ARTIFACT/ARTIFACT_DATA_ERASED', payload: {}} as const),
}

type ThunkType = BaseThunkType<ActionsType | AppActionsType | ExpositionActionsType>

export const getArtifactsData = (token: string, id: number): ThunkType => {
    return async (dispatch) => {
        dispatch(appActions.toggleIsFetching(true))
        try {
            let data = await artifactAPI.getArtifactDataList(token, id)
            console.log('getArtifactData', data)
            if(!data.status) {
                dispatch(actions.artifactsListReceived(data.artifacts))
                setExpositionInfo(data.exposition_id, data.exposition_type,data.exposition_name)
                addExpositionItemInArray(token, data.exposition_id, data.exposition_type, data.exposition_name)
                dispatch(expositionActions.setExpositionParams(data.exposition_id, data.exposition_name, data.exposition_type))
            }
            else if(data.status === StatusCodesEnum.Forbidden) {
                if(data.message && data.message === 'Object is archived') {
                    dispatch(appActions.setArchived(true))
                }
                else {
                    removeExpositionItemFromArray(token, Number(localStorage.getItem('exposition_id')), localStorage.getItem('exposition_type') as 'Свбодный вход' & 'По билетам')
                    dispatch(appActions.setForbiddenError(true))
                }
            }
            else if(data.status === StatusCodesEnum.NotFound) {
                dispatch(appActions.setNotFoundError(true))
            }
            else if(data.status === StatusCodesEnum.BadRequest) {
                removeExpositionItemFromArray(token, Number(localStorage.getItem('exposition_id')), localStorage.getItem('exposition_type') as 'Свбодный вход' & 'По билетам')
                removeExpositionInfo()
                dispatch(appActions.setServerError(true))
            }
            dispatch(appActions.toggleIsFetching(false))
        }
        catch (e: any) {
            console.error('getArtifactData', e.config)
            dispatch(appActions.toggleIsFetching(false))
            dispatch(appActions.setServerError(true))
        }
    }
}

export default artifactReducer;