import React, {useRef} from 'react';
import cl from 'classnames'
import s from './SelectSizeModal.module.scss'
import ReactToPrint from "react-to-print";
import ComponentToPrint from "../../Print/ComponentToPrint";
const SelectSizeModal = ({setPrintSize, selectedItems, isIcoExists, printType, printSize, section}) => {
    const componentRef = useRef()
    return (
        <div className={s.modal}>
            <div className={s.title}>
                Выбор размера печати
            </div>
            <div className={s.text}>
                Пожалуйста выберите размер листов для печати
            </div>
            <div className={s.buttons}>
                {
                    section === 'rooms' &&
                        <>
                            <button onClick={() => setPrintSize('large')} className={cl(printSize === 'large' ? s.active : s.button)}>Большой</button>
                            <button onClick={() => setPrintSize('medium')} className={cl(printSize === 'medium' ? s.active : s.button)}>Средний</button>
                            <button onClick={() => setPrintSize('small')} className={cl(printSize === 'small' ? s.active : s.button)}>Маленький</button>
                        </>
                }
                {
                    section === 'artifacts' &&
                        <>
                            {
                                printType === 'qr+ico' ?
                                    <>
                                        <button onClick={() => setPrintSize('large')} className={cl(printSize === 'large' ? s.active : s.button)}>Большой</button>
                                        <button onClick={() => setPrintSize('medium')} className={cl(printSize === 'medium' ? s.active : s.button)}>Средний</button>
                                    </>
                                    :
                                    <>
                                        <button onClick={() => setPrintSize('large')} className={cl(printSize === 'large' ? s.active : s.button)}>Большой</button>
                                        <button onClick={() => setPrintSize('medium')} className={cl(printSize === 'medium' ? s.active : s.button)}>Средний</button>
                                        <button onClick={() => setPrintSize('small')} className={cl(printSize === 'small' ? s.active : s.button)}>Маленький</button>
                                    </>
                            }
                        </>
                }
            </div>
            <ReactToPrint
                content={() => componentRef.current}
                trigger={() =>
                    // <button disabled={
                    //     section === 'rooms'
                    //     ? (printSize !== 'large' && printSize !== 'medium')
                    //     : (section === 'artifacts' && (
                    //         ( (printType === 'qr+ico' && (printSize !== 'large' && printSize !== 'medium') ) ||
                    //             ((printType === 'qr' || printType === 'ico') && (printSize !== 'medium' && printSize !== 'small'))
                    //         )
                    //         ||
                    //     (printSize === '' || selectedItems?.length === 0)))
                    // } className={s.printButton}
                    // >
                    <button disabled={printSize === '' || selectedItems?.length === 0} className={s.printButton}>
                        Печать
                    </button>

                }
            />
            <div className={s.printComponentContainer}>
                <ComponentToPrint ref={componentRef} printType={printType} printSize={printSize} isIcoExists={isIcoExists}
                                  selectedItems={selectedItems} section={section} />
            </div>
        </div>
    );
};

export default SelectSizeModal;