import React, {FC} from 'react';
import s from './ConnectedRoomList.module.scss'
import {ConnectedRoomType} from "../../../../types/Types";
import cl from "classnames";
import ConnectedRoomItem from "./ConnectedRoomItem/ConnectedRoomItem";
import unvisited from "../../../../assets/images/unvisited_room_circle.svg";
import visited from "../../../../assets/images/visited_room_mark.svg";

const ConnectedRoomList:FC<MyProps> = ({isVisited, rooms, title}) => {
    return (
        <>
            {
                rooms.length > 0 &&
                <div className={cl(s.room, isVisited ? s.visited : s.unvisited)}>
                    <div className={s.title}>
                        {title}
                    </div>
                    {rooms.map((room: ConnectedRoomType) => {
                        return (
                            <ConnectedRoomItem key={room.id} room={room} icon={isVisited ? visited : unvisited}/>
                        )
                    })}
                </div>
            }
        </>
    );
};

export default ConnectedRoomList;

type MyProps = {
    rooms: Array<ConnectedRoomType>
    title: string
    isVisited: boolean
}