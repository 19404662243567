import {BaseThunkType, InferActionsTypes} from "./redux-store";
import {ConnectedRoomType} from "../types/Types";
import {connectedRoomsAPI} from "../api/connectedRooms-api";
import {appActions, AppActionsType} from "./app-reducer";
import {StatusCodesEnum} from "../api/api";
import {expositionActions, ExpositionActionsType} from "./exposition-reducer";
import {
    addExpositionItemInArray,
    removeExpositionInfo,
    removeExpositionItemFromArray,
    setExpositionInfo
} from "../utils/utils";

export type InitialStateType = typeof initialState
let initialState = {
    connected_rooms: [] as Array<ConnectedRoomType>,
    currentRoom: {} as ConnectedRoomType,
}

const connectedRoomsReducer = (state = initialState, action: ActionsType):InitialStateType  => {
    switch (action.type) {
        case 'AW/CONNECTED/CONNECTED_ROOMS_LIST_RECEIVED':
            return {
                ...state,
                connected_rooms: action.payload.connected_rooms,
                currentRoom: action.payload.currentRoom,
            }
        case 'AW/CONNECTED/CONNECTED_ROOMS_LIST_ERASED':
            return {
                ...state,
                connected_rooms: [],
            }

        default:
            return state;
    }
}

export type ActionsType = InferActionsTypes<typeof actions>

export const actions = {
    connectedRoomsListReceived: (connected_rooms: Array<ConnectedRoomType>, currentRoom: ConnectedRoomType) =>
        ({type: 'AW/CONNECTED/CONNECTED_ROOMS_LIST_RECEIVED', payload: {connected_rooms, currentRoom}} as const),
    connectedRoomsListErased: () =>
        ({type: 'AW/CONNECTED/CONNECTED_ROOMS_LIST_ERASED'} as const),
}

type ThunkType = BaseThunkType<ActionsType | AppActionsType | ExpositionActionsType>

export const getConnectedRoomsList = (token: string, id: number): ThunkType => {
    return async (dispatch) => {
        dispatch(appActions.toggleIsFetching(true))
        try {
            const data = await connectedRoomsAPI.getConnectedRoomsList(token, id)
            console.log('connectedRoomsListReceived', data)
            if(!data.status) {
                dispatch(actions.connectedRoomsListReceived(data.connected_rooms, data.room))
                setExpositionInfo(data.exposition_id, data.exposition_type, data.exposition_name)
                addExpositionItemInArray(token, data.exposition_id, data.exposition_type, data.exposition_name)
                dispatch(expositionActions.setExpositionParams(data.exposition_id, data.exposition_name, data.exposition_type))
            }
            else if(data.status === StatusCodesEnum.Forbidden) {
                removeExpositionItemFromArray(token, Number(localStorage.getItem('exposition_id')), localStorage.getItem('exposition_type') as 'Свбодный вход' & 'По билетам')
                dispatch(appActions.setForbiddenError(true))
            }
            else if(data.status === StatusCodesEnum.NotFound) {
                dispatch(appActions.setNotFoundError(true))
            }
            else if(data.status === StatusCodesEnum.BadRequest) {
                removeExpositionItemFromArray(token, Number(localStorage.getItem('exposition_id')), localStorage.getItem('exposition_type') as 'Свбодный вход' & 'По билетам')
                removeExpositionInfo()
                dispatch(appActions.setServerError(true))
            }
            dispatch(appActions.toggleIsFetching(false))
        }
        catch (e: any) {
            console.error('connectedRoomsListReceived', e.config)
            dispatch(appActions.toggleIsFetching(false))
            dispatch(appActions.setServerError(true))
        }
    }
}

export default connectedRoomsReducer;