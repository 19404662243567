import React, {FC} from 'react';
import s from "./ConnectedRoomItem.module.scss";
import {NavLink} from "react-router-dom";
import {ConnectedRoomType} from "../../../../../types/Types";
import photo from '../../../../../assets/images/photo_absence.svg'

const ConnectedRoomItem:FC<{room:ConnectedRoomType, icon:string }> = ({room, icon}) => {

    return (
        <NavLink to={`/rooms/${room.id}`} className={s.roomContainer}>
            <div className={s.imgContainer}>
                {
                    room.img !== null ?
                        <picture>
                            <source media='(max-width: 650px)' srcSet={room.img_compressed_resized} />
                            <img className={s.img} src={room.img} alt='artifact' />
                        </picture>
                    :
                    <img className={s.absence} src={photo} alt='room' />
                }
                <div className={s.circle}></div>
            </div>

            <div className={s.name}>
                <span>
                    {room.name}
                </span>
                <img src={icon} alt='mark' />
            </div>
        </NavLink>
    );
};

export default ConnectedRoomItem;