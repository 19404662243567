import React, {MouseEventHandler} from "react";
import cl from "classnames";
import s from "./PhotoSlider.module.scss";
import left from "../../assets/images/left_arrow_slider.svg";

export const renderLeftNav = (onClick: MouseEventHandler<HTMLButtonElement>) => {
    return (
        <button
            className={cl(s.left, s.arrow)}
            onClick={onClick}>
            <div>
                <img src={left} alt='left arrow' />
            </div>
        </button>
    )
}