import React, {FC} from 'react';
import s from './LoginError.module.scss'

const LoginError:FC<MyProps> = ({isError, text}) => {
    return (
        <>
            {
                isError &&
                <div className={s.error}>
                    {text}
                </div>
            }
        </>
    );
};

export default LoginError;

type MyProps = {
    isError: boolean
    text: string
}