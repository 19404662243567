import {ErrorResponseType, baseURL} from "./api";
import axios from "axios";
import {ExpositionType} from "../types/Types";

type ExpositionResponseDataType = {
    exposition: ExpositionType,
    ticket_remaining_lifetime: string,
}

export const expositionAPI = {
    getExpositionData(token: string, exposition_id: number, exposition_type: 'По билетам' | 'Свободный вход') {
        let formdata = new FormData();
        formdata.append('token', token);
        formdata.append('exposition_pk', String(exposition_id));
        formdata.append('exposition_type', exposition_type);

        return axios.post<ExpositionResponseDataType & ErrorResponseType>(baseURL + `api/exposition`, formdata)
            .then(response => response.data)
    },
}