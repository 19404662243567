import React, {FC} from 'react';
import s from "./RoomItems.module.scss";
import {PrintRoomType} from "../../../../../types/Types";
import RoomPrintItem from "../RoomPrintItem/RoomPrintItem";
import {useSelector} from "react-redux";
import {AppStateType} from "../../../../../redux/redux-store";
import Preloader from "../../../../../common/Preloader/Preloader";

const RoomItems:FC<{printType: string}> = ({printType}) => {
    const rooms = useSelector((state: AppStateType) => state.print.rooms)
    const isFetch = useSelector((state: AppStateType) => state.app.isFetch)
    const selectedItems = useSelector((state: AppStateType) => state.print.selectedItems)
    if(isFetch) {
        return <Preloader />
    }
    return (
        <>
            {
                rooms?.length > 0 ?
                    <div className={s.rooms}>
                        {
                            printType === 'qr' ?
                                rooms.map((room: PrintRoomType) => {
                                    return (
                                        <div key={room.id}>
                                            {
                                                selectedItems.some((item) => item.id === room.id) ?
                                                    <RoomPrintItem active={true} room={room} />
                                                    :
                                                    <RoomPrintItem active={false} room={room} />
                                            }
                                        </div>
                                    )
                                })
                                :
                                rooms.filter((roomItem: PrintRoomType) => roomItem.icon !== null)
                                    .map((room:PrintRoomType) => {
                                        return (
                                            <div key={room.id}>
                                                {
                                                    selectedItems.some((item) => item.id === room.id) ?
                                                        <RoomPrintItem active={true} room={room} />
                                                        :
                                                        <RoomPrintItem active={false} room={room} />
                                                }
                                            </div>
                                        )
                                    })
                        }
                    </div>
                    :
                    <div className={s.notFound}>
                        Комнаты не найдены
                    </div>
            }
        </>
    );
};

export default RoomItems;