import React, {Component} from 'react';
import TicketPrint from "./TicketPrint/TicketPrint";
import LargeQr from "./LargeQr/LargeQr";
import MediumQr from "./MediumQr/MediumQr";
import SmallQr from './SmallQr/SmallQr';
import {AliveTicketType, ArtifactItemType, PrintRoomType} from "../../types/Types";
import {Redirect} from "react-router-dom";
import {FourContainer, OneContainer, TwoContainer } from './Common/CommonStyled';
import CommonLogic from "./Common/CommonLogic";

class ComponentToPrint extends Component<MyProps, MyState> {
    //Тут я решаю какие билеты печатать
    render() {
        if(this.props.section !== 'tickets' && this.props.section !== 'rooms' && this.props.section !== 'artifacts') {
            return <Redirect to={'/not_found'} />
        }

        return (
            <>
                {
                    this.props.section === 'tickets' &&
                        <OneContainer>
                            <CommonLogic selectedItems={this.props.selectedItems} Component={TicketPrint} section={this.props.section} />
                        </OneContainer>
                }
                {
                    this.props.section === 'rooms' &&
                        <>
                            {/*{*/}
                            {/*    this.props.printType === 'qr+ico' && this.props.printSize === 'large' &&*/}
                            {/*    <OneContainer>*/}
                            {/*        <CommonLogic selectedItems={this.props.selectedItems} Component={LargeQrAndIco} section={this.props.section} />*/}
                            {/*    </OneContainer>*/}
                            {/*}*/}
                            {/*{*/}
                            {/*    this.props.printType === 'qr+ico' && this.props.printSize === 'medium' &&*/}
                            {/*    <TwoContainer>*/}
                            {/*        <CommonLogic selectedItems={this.props.selectedItems} Component={MediumQrAndIco} section={this.props.section} />*/}
                            {/*    </TwoContainer>*/}
                            {/*}*/}
                            {/*{*/}
                            {/*    this.props.printType === 'ico' && this.props.printSize === 'large' &&*/}
                            {/*    <TwoContainer>*/}
                            {/*        <CommonLogic selectedItems={this.props.selectedItems} Component={LargeIco} section={this.props.section} />*/}
                            {/*    </TwoContainer>*/}
                            {/*}*/}
                            {
                                this.props.printType === 'qr' && this.props.printSize === 'large' &&
                                <OneContainer>
                                    <CommonLogic selectedItems={this.props.selectedItems} Component={LargeQr} section={this.props.section} />
                                </OneContainer>
                            }
                            {
                                this.props.printType === 'qr' && this.props.printSize === 'medium' &&
                                <TwoContainer>
                                    <CommonLogic selectedItems={this.props.selectedItems} Component={MediumQr} section={this.props.section} />
                                </TwoContainer>
                            }
                            {
                                this.props.printType === 'qr' && this.props.printSize === 'small' &&
                                <FourContainer>
                                    <CommonLogic selectedItems={this.props.selectedItems} Component={SmallQr} section={this.props.section} />
                                </FourContainer>
                            }
                            {/*{*/}
                            {/*    this.props.printType === 'ico' && this.props.printSize === 'medium' &&*/}
                            {/*    <TwoContainer>*/}
                            {/*        <CommonLogic selectedItems={this.props.selectedItems} Component={MediumIco} section={this.props.section} />*/}
                            {/*    </TwoContainer>*/}
                            {/*}*/}
                        </>
                }
                {
                    this.props.section === 'artifacts' &&
                        <>
                            {/*{*/}
                            {/*    this.props.printType === 'qr+ico' && this.props.printSize === 'large' &&*/}
                            {/*    <OneContainer>*/}
                            {/*        <CommonLogic selectedItems={this.props.selectedItems} Component={LargeQrAndIco} section={this.props.section} />*/}
                            {/*    </OneContainer>*/}
                            {/*}*/}
                            {/*{*/}
                            {/*    this.props.printType === 'ico' && this.props.printSize === 'medium' &&*/}
                            {/*    <TwoContainer>*/}
                            {/*        <CommonLogic selectedItems={this.props.selectedItems} Component={MediumIco} section={this.props.section} />*/}
                            {/*    </TwoContainer>*/}
                            {/*}*/}
                            {
                                this.props.printType === 'qr' && this.props.printSize === 'large' &&
                                <OneContainer>
                                    <CommonLogic selectedItems={this.props.selectedItems} Component={LargeQr} section={this.props.section} />
                                </OneContainer>
                            }
                            {
                                this.props.printType === 'qr' && this.props.printSize === 'medium' &&
                                <TwoContainer>
                                    <CommonLogic selectedItems={this.props.selectedItems} Component={MediumQr} section={this.props.section} />
                                </TwoContainer>
                            }
                            {/*{*/}
                            {/*    this.props.printType === 'qr+ico' && this.props.printSize === 'medium' &&*/}
                            {/*    <TwoContainer>*/}
                            {/*        <CommonLogic selectedItems={this.props.selectedItems} Component={MediumQrAndIco} section={this.props.section} />*/}
                            {/*    </TwoContainer>*/}
                            {/*}*/}
                            {
                                this.props.printType === 'qr' && this.props.printSize === 'small' &&
                                <FourContainer>
                                    <CommonLogic selectedItems={this.props.selectedItems} Component={SmallQr} section={this.props.section} />
                                </FourContainer>
                            }
                            {/*{*/}
                            {/*    this.props.printType === 'ico' && this.props.printSize === 'small' &&*/}
                            {/*    <FourContainer>*/}
                            {/*        <CommonLogic selectedItems={this.props.selectedItems} Component={SmallIco} section={this.props.section} />*/}
                            {/*    </FourContainer>*/}
                            {/*}*/}
                        </>
                }
            </>
        );
    }
}

export default ComponentToPrint;

type MyProps = {
    section: string,
    printSize: string,
    printType: string,
    selectedItems: Array<AliveTicketType | PrintRoomType | ArtifactItemType>,
};
type MyState = {

};