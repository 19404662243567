import React, {FC} from 'react';
import s from "./ArtifactsImages.module.scss";
import PhotoSlider from "../../../../common/PhotoSlider/PhotoSlider";
import {ReactComponent as Slide} from "../../../../assets/images/Swipe.svg";
import {ArtifactType} from "../../../../types/Types";

const ArtifactsImages:FC<MyProps> = ({artifactData, displayIco = true}) => {
    return (
        <>
            <div className={s.sliderContainer}>
                <PhotoSlider images={artifactData.images} />
                {
                    displayIco &&
                    <div className={s.slideIco}>
                        <div className={s.blur}></div>
                        <Slide width={22} height={26} fill={'white'} />
                    </div>
                }
            </div>
        </>
    );
};

export default ArtifactsImages;

type MyProps = {
    artifactData: ArtifactType
    displayIco?: boolean
}