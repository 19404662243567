import React from 'react';
import s from './PhotoAbsence.module.scss'
import photo from '../../assets/images/photo_absence.svg'

const PhotoAbsence = () => {
    return (
        <div className={s.container}>
            <img className={s.img} src={photo} alt='photo_absence' />
        </div>
    );
};

export default PhotoAbsence;