import React, {FC} from 'react';
import s from './EnterId.module.scss'
import arrow_back from "../../../../assets/images/arrow_back.svg";
import {useHistory} from "react-router-dom";

const EnterId:FC<MyProps> = ({inputId, handleChange}) => {
    const history = useHistory()

    return (
        <>
            <div className='pageTitle'>
                <button onClick={() => history.go(-1)}>
                    <img src={arrow_back} alt='arrow_back' />
                </button>
                Ввод ID
            </div>
            <div className={s.inputContainer}>
                <input placeholder='ID' type='text' value={inputId}
                       onChange={(e) => handleChange(e.target.value)}/>
            </div>
            <div className={s.prompt}>
                Подсказка: идентификатор экспоната находится над QR-кодом
            </div></>
    );
};

export default EnterId;

type MyProps = {
    inputId: string
    handleChange: (value: string) => void
}