import React, {MouseEventHandler} from "react";
import cl from "classnames";
import s from "./PhotoSlider.module.scss";
import right from "../../assets/images/right_arrow_slider.svg";

export const renderRightNav = (onClick: MouseEventHandler<HTMLButtonElement>) => {
    return (
        <button
            className={cl(s.right, s.arrow)}
            onClick={onClick}>
            <div>
                <img src={right} alt='right arrow' />
            </div>
        </button>
    )
}