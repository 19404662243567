import React, {FC} from 'react';
import s from "./RoomArtifacts.module.scss";
import ArtifactItem from "./ArtifactItem/ArtifactItem";
import {RoomType} from "../../../../types/Types";

const RoomArtifacts:FC<MyProps> = ({roomData}) => {
    return (
        <>
            {
                roomData?.artifacts?.length > 0
                ?
                <>
                    <div className={s.subtitle}>
                        Список экспонатов
                    </div>
                    {
                        roomData.artifacts.map((ar) => {
                            return (
                                <div key={ar.id}>
                                    <ArtifactItem artifact={ar} />
                                </div>
                            )
                        })
                    }
                </>
                :
                <div className={s.subtitle}>
                    Экспонаты отсутствуют
                </div>
            }
        </>
    );
};

export default RoomArtifacts;

type MyProps = {
    roomData: RoomType
}