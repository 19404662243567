import React, {FC} from 'react';
import s from './MapImage.module.scss'
import {MapType} from "../../../../types/Types";
import MapImageItem from "./MapImageItem/MapImageItem";

const MapImage:FC<{rooms: Array<MapType>, image: string}> = ({rooms, image}) => {

    return (
        <div className={s.container}>
            <img className={s.map} src={image} alt="map"/>
            {
                rooms?.length > 0 &&
                rooms.map((item: MapType) => {
                    return (
                        <MapImageItem id={item.id} key={item.id} isVisited={item.isVisited} isCurrentRoom={item.isCurrentRoom}
                                      name={item.name} x_axis={item.map_x_axis} y_axis={item.map_y_axis} />
                    )
                })
            }
        </div>
    );
};

export default MapImage;