import React, {useEffect, useState} from 'react';
import s from './Information.module.scss'
import {NavLink} from 'react-router-dom';
import AloneLogoTop from "../../../common/AloneLogoTop/AloneLogoTop";
import arrow_back from "../../../assets/images/arrow_back.svg";
import 'swiper/scss'
import 'swiper/scss/effect-cards'
import SwiperCore, {EffectCards} from 'swiper';
import InformationStep from "./InformationStep/InformationStep";
import InformationSwiper from "./InformationSwiper/InformationSwiper";

SwiperCore.use([EffectCards]);

const Information = () => {
    const [step, setStep] = useState(1)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={s.container}>
            <div className={s.top}>
                <AloneLogoTop />
                <div className='pageTitle'>
                    <NavLink to='/'>
                        <img src={arrow_back} alt='arrow_back' />
                    </NavLink>
                    Обучение
                </div>
            </div>
            <div className={s.content}>
                <InformationSwiper setStep={setStep} />
                <InformationStep step={step} />
            </div>
        </div>
    );
};

export default Information;