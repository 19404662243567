import React, {FC} from 'react';
import s from "./InformationSwiper.module.scss";
import {Swiper, SwiperSlide} from "swiper/react";
import {EffectCards} from "swiper";
import InformationItem from "./InformationItem/InformationItem";
import information_1 from "../../../../assets/images/information_1.svg";
import information_2 from "../../../../assets/images/information_2.svg";
import information_3 from "../../../../assets/images/information_3.svg";
import information_4 from "../../../../assets/images/information_4.svg";
import information_5 from "../../../../assets/images/information_5.svg";

const InformationSwiper:FC<MyProps> = ({setStep}) => {
    return (
        <div className={s.swiper}>
            <Swiper
                effect={'cards'}
                grabCursor={true}
                modules={[EffectCards]}
                onSlideChange={(e) => {
                    setStep(e.activeIndex + 1)
                }}
            >
                <SwiperSlide>
                    <InformationItem number={1} img={information_1} text='Если вход осуществляется по билетам, для начала отсканируйте QR-код из билета' alt='scan ticket' />
                </SwiperSlide>
                <SwiperSlide>
                    <InformationItem number={2} img={information_2} text='Теперь Вы можете сканировать QR-коды комнат и экспонатов. Вперед!' alt='scan artifact' />
                </SwiperSlide>
                <SwiperSlide>
                    <InformationItem number={3} img={information_3} text='На странице комнаты Вы можете открыть страницу со списком соседних комнат, а на ней - карту выставки' alt='rooms' />
                </SwiperSlide>
                <SwiperSlide>
                    <InformationItem number={4} img={information_4} text='На карте Вы можете увидеть структуру экспозиции. Нажав на любую комнату, Вы, соответственно, перейдете к ней' alt='map' />
                </SwiperSlide>
                <SwiperSlide>
                    <InformationItem number={5} img={information_5} text='Вы всегда можете нажать на логотип сервиса и перейти к описанию самой выставки. Там же можно будет перейти к карте, а также к комнате или к экспонату как с помощью сканера QR-кодов, так и с помощью перехода по ID' alt='exposition' />
                </SwiperSlide>
            </Swiper>
        </div>
    );
};

export default InformationSwiper;

type MyProps = {
    setStep: (value: number) => void
}