import React, {FC, useEffect, useState} from 'react';
import s from './Tickets.module.scss'
import {useDispatch} from "react-redux";
import {actions, getAliveTickets} from "../../../../redux/print-reducer";
import {AliveTicketType} from "../../../../types/Types";
import TitleItem from "../TitleItem/TitleItem";
import {ReactComponent as Ticket} from "../../../../assets/images/print_ticket.svg";
import Search from "../../../../common/Search/Search";
import TicketItems from "./TicketItems/TicketItems";

const Tickets:FC<{expositionName: string}> = ({expositionName}) => {
    const dispatch = useDispatch()
    const [letters, setLetters] = useState('')

    useEffect(() => {
        dispatch(getAliveTickets(letters))
        return () => {
            dispatch(actions.aliveTicketsReceived(expositionName, [] as Array<AliveTicketType>))
            dispatch(actions.removeSelectedItems())
        }
    }, [dispatch])

    const handleKeyUp = (e:any) => {
        if(e.keyCode === 13) {
            dispatch(getAliveTickets(letters))
        }
    }

    const handleSubmit = () => {
        dispatch(getAliveTickets(letters))
    }

    return (
        <>
            <div className={s.top}>
                <div className={s.name}>
                    <TitleItem Ico={Ticket} title='Билеты' />
                    <div className={s.exposition}>
                        Выставка
                        «{expositionName}»
                    </div>
                </div>
                <Search handleSubmit={handleSubmit} handleKeyUp={handleKeyUp} setLetters={setLetters} letters={letters} />
            </div>

            <TicketItems />

        </>
    );
};

export default Tickets;