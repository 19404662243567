import React, {FC, ReactInstance, RefObject, useRef} from 'react';
import s from "./PrintHeader.module.scss";
import {useRouteMatch} from "react-router-dom";
import ReactToPrint from "react-to-print";
import ComponentToPrint from "../../../../common/Print/ComponentToPrint";
import {
    AliveTicketType,
    ArtifactItemType,
    PrintRoomType,
    PrintSectionsEnum,
    PrintSizeEnum,
    PrintTypeEnum
} from "../../../../types/Types";
import PrintHeaderNav from "./PrintHeaderNav/PrintHeaderNav";

const PrintHeader:FC<MyProps> = ({selectedItems, handleLogout, setIsSelectSizeModalOpen, printSize, printType}) => {
    const match = useRouteMatch<MatchParams>();
    const componentRef = useRef<ReactInstance | null>(null);
    return (
        <header className={s.header}>
            <div className={s.headerContainer}>
                <PrintHeaderNav />
                <div className={s.buttons}>
                    {
                        match.params.section === 'tickets' ?
                            <>
                                <ReactToPrint
                                    content={() => componentRef.current}
                                    trigger={() =>
                                        <button disabled={selectedItems.length === 0} className={s.printBtn}> Перейти к печати
                                            <div>{selectedItems.length}</div>
                                        </button>
                                    }
                                />
                                <div className={s.printComponentContainer}>
                                    <ComponentToPrint ref={componentRef as ((string | ((instance: ComponentToPrint | null) => void) | RefObject<ComponentToPrint>) & ReactInstance) | null | undefined} printType={printType} printSize={printSize} selectedItems={selectedItems} section={match.params.section} />
                                </div>
                            </>
                            : (
                                (match.params.section === 'rooms' || match.params.section === 'artifacts') &&
                                <button disabled={selectedItems.length === 0} onClick={() => setIsSelectSizeModalOpen(true)}
                                        className={s.printBtn}> Перейти к печати
                                    <div>{selectedItems.length}</div>
                                </button>
                            )
                    }
                    <button onClick={handleLogout} className={s.logout}>Выйти</button>
                </div>
            </div>
        </header>
    );
};

export default PrintHeader;

type MyProps = {
    handleLogout: () => void
    selectedItems: Array<AliveTicketType | PrintRoomType | ArtifactItemType>
    setIsSelectSizeModalOpen: (isSelected: boolean) => void
    printType: PrintTypeEnum
    printSize: PrintSizeEnum
}

type MatchParams = {
    section: PrintSectionsEnum
}