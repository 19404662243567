import React, {FC, useEffect, useState} from 'react'
import s from './Exposition.module.scss'
import AloneLogoTop from '../../../common/AloneLogoTop/AloneLogoTop'
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {AppStateType} from "../../../redux/redux-store";
import {expositionActions, getExpositionData} from "../../../redux/exposition-reducer";
import Welcome from "./Welcome/Welcome";
import {removeExpositionInfo} from "../../../utils/utils";
import {appActions} from "../../../redux/app-reducer";
import {ExpositionType} from "../../../types/Types";
import Preloader from "../../../common/Preloader/Preloader";
import ExpositionNavigation from "./ExpositionNavigation/ExpositionNavigation";
import ExpositionInfo from "./ExpositionInfo/ExpositionInfo";

const Exposition: FC = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [id, setLocalId] = useState(localStorage.getItem('exposition_id'))
    const type = localStorage.getItem('exposition_type')
    const exposition = useSelector((state:AppStateType) => state.exposition.exposition)
    const ticket_remaining_lifetime = useSelector((state:AppStateType) => state.exposition.ticket_remaining_lifetime)
    const exposition_type = useSelector((state:AppStateType) => state.exposition.exposition_type)
    const exposition_id = useSelector((state:AppStateType) => state.exposition.exposition_id)
    const isFetch = useSelector((state:AppStateType) => state.app.isFetch)
    const token = useSelector((state:AppStateType) => state.app.token)

    const url = new URLSearchParams(history.location.search)
    //token с query параметров
    const [urlToken] = useState(url.get('token'))
    //Локальный токен из памяти приложения
    const [localToken] = useState(localStorage.getItem('token'))

    useEffect(() => {
        //Если есть токен, то идет запрос к экспозиции
        if(token !== '' && token !== null) {
            dispatch(getExpositionData(token, 0, 'По билетам'))
        }
        //Если два токена оказались равны, то не перезатираем массив комнат
        //Иначе
        if(urlToken !== localToken) {
            //Если токены отличаются, значит у нас другой билет и комнаты стоит занулить
            if(urlToken !== null) {
                localStorage.setItem('visited_rooms', JSON.stringify([]))
            }
            //Если токены отличаются, остается старый токен и старый массив комнат
            else if(localToken !== null) {}
        }

    }, [dispatch, urlToken, token, localToken])

    useEffect(() => {
        if(type === 'Свободный вход') {
            if(id !== null && !isNaN(Number(id))) {
                dispatch(getExpositionData('', Number(id), type))
            }
            else if(exposition_id !== 0) {
                dispatch(getExpositionData('', exposition_id, type))
            }
        }
    }, [dispatch])

    const handleExit = () => {
        removeExpositionInfo()
        history.push('/')
        setLocalId(null)
        dispatch(appActions.tokenReceived(''))
        dispatch(expositionActions.setExpositionParams(0, '', ''))
        dispatch(expositionActions.expositionDataReceived({} as ExpositionType, ''))
    }

    if(isFetch) {
       return <Preloader />
    }
    if(token === '' && (id === null || type === null)) {
        return <Welcome />
    }

    return (
        <div className={s.container}>
            <div className={s.top}>
                <div className={s.nav}>
                    <AloneLogoTop />
                    <button onClick={() => handleExit()} className={s.exitBtn}>
                        Закончить просмотр
                    </button>
                </div>
                <h2 className={s.name}>
                    Добро пожаловать на выставку
                    <div>«{exposition.name}»</div>
                </h2>
                <ExpositionInfo exposition={exposition} exposition_type={exposition_type} ticket_remaining_lifetime={ticket_remaining_lifetime} />
            </div>
            <ExpositionNavigation />
        </div>
    )
}

export default Exposition