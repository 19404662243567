import React, {FC} from 'react'
import s from './SmallBtn.module.scss'
import {NavLink} from 'react-router-dom'

const SmallBtn: FC<{img: string, text:string, link: string}> = ({img, text, link}) => {
    return (
        <NavLink exact to={link} className={s.link}>
            <img className={s.icon} src={img} alt='icon' />
            <span className={s.text}>
                {text}
            </span>
        </NavLink>
    )
}

export default SmallBtn