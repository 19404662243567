import React, {FC, useState} from 'react';
import {NavLink, Route} from 'react-router-dom';
import QrReader from 'react-qr-reader'
import s from './QrScan.module.scss'
import AloneLogoTop from "../../../common/AloneLogoTop/AloneLogoTop";
import arrow_back from '../../../assets/images/arrow_back.svg'
import {ReactComponent as Rotate} from '../../../assets/images/camera_rotate.svg'
import {baseURL} from "../../../api/api";
import EnterBottomLink from "../../../common/EnterBottomLink/EnterBottomLink";

const QrScan: FC =  () => {
    const [result, setResult] = useState<string | null>(null)
    const [facingMode, setFacingMode] = useState<'environment' | 'user'>('environment')
    const [error, setError] = useState(false)

    const previewStyle = {
        width: '100%',
        marginTop: 20,
        marginBottom: 20,
        borderRadius: 12,
    }

    const changeCamera = (currentFacingMode: 'environment' | 'user') => {
        setFacingMode(currentFacingMode === 'environment' ? 'user' : 'environment')
    }

    const resetError = () => {
        setTimeout(() => {
            setError(false)
        }, 10000)
    }

    const handleScan = (data: string | null) => {
        setResult(data)
    }
    const handleError = (err: Error) => {
        setError(true)
        resetError()
    }


    if(baseURL !== undefined) {
        if(result !== null && result.includes(baseURL)) {
            return <Route path='/' component={() => {
                // @ts-ignore
                window.location = `${result}`; return null;} }/>
        }
        else if (result !== null && !result.includes(baseURL) && !error) {
            setError(true)
            resetError()
        }
    }
    else if (result !== null && !error) {
        setError(true)
        resetError()
    }

    return (
        <div className={s.container}>
            <div className={s.top}>
                <AloneLogoTop />
                <div className='pageTitle'>
                    <NavLink to='/'>
                        <img src={arrow_back} alt='arrow_back' />
                    </NavLink>
                    Сканирование QR
                </div>
                <div className={'qr-image-wrapper'}>
                    <QrReader
                        delay={300}
                        onError={handleError}
                        onScan={handleScan}
                        facingMode={facingMode}
                        style = {previewStyle}
                    />
                    <button className={s.rotate} onClick={() => changeCamera(facingMode)}><Rotate width={25} height={25} fill={'white'}/></button>
                </div>
                <div className={s.rule}>
                    Наведите камеру на QR-код
                </div>
                {
                    error ?
                    <div className={'error'}>
                        Данный QR-код не относится к сервису ArtWay
                    </div>
                    :
                    <div className={s.empty}></div>
                }
            </div>
            <EnterBottomLink />
        </div>
    )
};

export default QrScan;