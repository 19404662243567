import React, {ComponentType} from "react";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {AppStateType} from "../redux/redux-store";

let mapStateToPropsForRedirect = (state: AppStateType) => {
    return {
        forbiddenError: state.app.forbiddenError,
        serverError: state.app.serverError,
        isArchived: state.app.isArchived,
        notFoundError: state.app.notFoundError,
    };
}

type MapPropsType = {
    forbiddenError: boolean
    serverError: boolean
    isArchived: boolean
    notFoundError: boolean
}

export function withErrorRedirect<WCP> (WrappedComponent: ComponentType<WCP>) {

    const RedirectComponent: React.FC<MapPropsType> = (props) => {
        let {notFoundError,serverError, forbiddenError, isArchived, ...restProps} = props
        if (forbiddenError) return <Redirect to='/canceled' />
        else if (notFoundError) return <Redirect to='/not_found' />
        else if (isArchived) return <Redirect to='/archive' />
        else if (serverError) return <Redirect to='/server_error' />

        return (
            <WrappedComponent {...restProps as WCP} />
        )
    }

    let ConnectedErrorRedirectComponent = connect<MapPropsType, {}, WCP, AppStateType>(mapStateToPropsForRedirect)(RedirectComponent);

    return ConnectedErrorRedirectComponent;
}