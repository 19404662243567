import React, {FC} from 'react';
import s from './EnterFooter.module.scss'
import {NavLink} from "react-router-dom";
import cl from "classnames";
import {ReactComponent as Arrow} from "../../../../assets/images/arrow_right.svg";

const EnterFooter:FC<MyProps> = ({isButtonDisable, inputId, splitString}) => {
    return (
        <div className={s.footer}>
            {
                !isButtonDisable ?
                    <NavLink className={cl(s.button, s.link)} to={Number(splitString(inputId)[0]) === 1 ? `/artifacts/${splitString(inputId)[1]}` : `/rooms/${splitString(inputId)[1]}`}>
                        Перейти
                        <Arrow fill='#fff' />
                    </NavLink>
                    :
                    <div className={cl(s.button, s.block)}>
                        Перейти
                        <Arrow fill='#fff' />
                    </div>
            }
        </div>
    );
};

export default EnterFooter;

type MyProps = {
    isButtonDisable: boolean
    splitString: (value: string) => Array<string>
    inputId: string
}