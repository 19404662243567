import React, {FC, useEffect, useState} from 'react';
import s from './Artifact.module.scss'
import AloneLogoTop from "../../../common/AloneLogoTop/AloneLogoTop";
import arrow_back from "../../../assets/images/arrow_back.svg";
import {NavLink, Redirect, useRouteMatch} from "react-router-dom";
import PhotoAbsence from "../../../common/PhotoAbsence/PhotoAbsence";
import Description from "../../../common/Description/Description";
import ArtifactAudio from "./ArtifactAudio/ArtifactAudio";
import ArtifactLinks from "./ArtifactLinks/ArtifactLinks";
import cl from "classnames";
import ArtifactsImages from "./ArtifactsImages/ArtifactsImages";
import {useDispatch, useSelector} from "react-redux";
import {AppStateType} from "../../../redux/redux-store";
import {actions, getArtifactsData} from "../../../redux/artifact-reducer";
import Preloader from "../../../common/Preloader/Preloader";
import ArtifactImagesSwiper from './ArtifactImagesSwiper/ArtifactImagesSwiper';

const Artifact:FC = () => {
    const dispatch = useDispatch()

    const artifactData = useSelector((state:AppStateType) => state.artifact.artifactData)
    const artifactSwiperItems = useSelector((state: AppStateType) => state.artifact.artifactSwiperItems)
    const isFetch = useSelector((state:AppStateType) => state.app.isFetch)
    const match = useRouteMatch<MatchType>()
    const token = useSelector((state:AppStateType) => state.app.token)

    const [isError, setIsError] = useState(false)
    const [step, setStep] = useState(0)
    const [playingIndex, setPlayingIndex] = useState(-1)

    const toggleAudio = (index: number) => {
        setPlayingIndex(index)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if(!isNaN(Number(match.params.id))) {
            dispatch(getArtifactsData(token, Number(match.params.id)))
        }
        else {
            setIsError(true)
        }

        return () => {
            dispatch(actions.artifactDataErased())
        }
    }, [dispatch, token, match.params.id])

    useEffect(() => {
        const visited_rooms = localStorage.getItem('visited_rooms')
        //Если пришла информация по комнате
        if(artifactData.id) {
            //В случае самого первого визита на страницу комнаты записываем текущую комнату в хранилище устройства, а остальные в
            //массив непосещенных
            if(visited_rooms === null) {
                localStorage.setItem('visited_rooms', JSON.stringify([artifactData.room]))
            }
            else {
                //массив айдишников посещенных комнат
                const rooms = JSON.parse(visited_rooms)
                //Есть ли текущая комната в массиве, добавляем, если нет
                const currentRoom = rooms.find((roomId: number) => roomId === Number(artifactData.room))
                if(!currentRoom) {
                    localStorage.setItem('visited_rooms', JSON.stringify([...rooms, artifactData.room]))
                }
            }
            setStep(artifactSwiperItems.findIndex((item) => item.isCurrent))
        }
    }, [artifactData])

    if(isError) {
        return <Redirect to='not_found' />
    }

    return (
        <>
            {
                isFetch && <Preloader />
            }
            <div className={cl(s.container)}>
                <div className={s.top}>
                    <div className={s.name}>
                        <AloneLogoTop />
                        <ArtifactImagesSwiper step={step} />
                        {
                            !isFetch &&
                            <div className='pageTitle'>
                                <NavLink to={`/rooms/${artifactData.room}`} >
                                    <img src={arrow_back} alt='arrow_back' />
                                </NavLink>
                                {artifactData.name}
                            </div>
                        }
                    </div>
                    {
                        (!isFetch && artifactData.images?.length > 0) ?
                        <ArtifactsImages artifactData={artifactData} displayIco={artifactData.images.length > 1} />
                        :
                        <PhotoAbsence />
                    }
                    {
                        !isFetch &&
                        <div className={s.content}>
                            <Description description={artifactData?.description} isMarkdown={artifactData.description_is_markdown} />
                            <ArtifactAudio audios={artifactData.audios} playingIndex={playingIndex} toggleAudio={toggleAudio} />
                            <ArtifactLinks links={artifactData.links} />
                        </div>
                    }
                </div>
            </div>
        </>
    );
};

export default Artifact;

type MatchType = {
    id: string
}